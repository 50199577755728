import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import ContactInfo from '../formComponents/ContactInfo'
import LocationInfo from '../formComponents/LocationInfo'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import IconButton from '@material-ui/core/IconButton'
import ExperienceInfo from '../formComponents/ExperienceInfo'
import AddPhoto from '../formComponents/AddPhoto'
import SubjectInfo from '../formComponents/SubjectInfo'

import { UserContext } from '../global/UserContext'
import config from '../../utils/config.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  backButton: {
    marginRight: theme.spacing(1),
    justifyContent: 'center'
  },
  instructions: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
  feet: {
    marginTop: theme.spacing(5),
  },
  disableBlueHover: {
    "&:hover": {
      color: theme.palette.primary.light,
    },
  }
}));

function getSteps() {
  return ['Upload Picture & Add Contact Info', 'Enter Location', 'Describe Your Experience', 'Choose Subjects'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Tell us about yourself';
    case 1:
      return "What area are you in?";
    case 2:
      return "Tell us about your experience"
    case 3:
      return "What subjects are you proficient in?"
    default:
      return 'Unknown stepIndex';
  }
}

const EducatorProgressStepper = () => {
  const { userDB } = useContext(UserContext)
  const { authState } = useOktaAuth()
  const classes = useStyles();

  // stepper information
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  // educator specific contact fields
  const [ displayName, setDisplayName] = useState(userDB.first_name)
  const [ email ] = useState(userDB.email)
  const [ userTypeEmail, setUserTypeEmail] = useState(userDB.email)
  const [ phone, setPhone ] = useState(null)

  // educator specific location fields
  const [ country, setCountry ] = useState(null)
  const [ region, setRegion ] = useState(null)
  const [ city, setCity ] = useState(null)

  // educator specific experience fields
  const [ experienceRows, setExperienceRows ] = useState([])
  const [ experienceError, setExperienceError ] = useState(false)

  // subjects to choose from
  const [checked, setChecked] = React.useState([-1]);
  const [ subjects, setSubjects ] = useState(null)
  const [ miscSubjects, setMiscSubjects ] = useState([])

  useEffect(() => {
    document.title = 'Malleable Minds • Registration'
    // get all subjects
    fetch(config.resourceServer + 'api/subjects')
    .then(response => {
      if (!response.ok) {
      return Promise.reject();
      }
      return response.json();
    })
    .then((data) => {
      setSubjects(data.subjects)
    })
    .catch((err) => console.error(err))

    // get educator information
    const { accessToken } = authState
    fetch(config.resourceServer + 'api/educators/' + userDB.email, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(response => {
      if (!response.ok) {
      return Promise.reject();
      }
      return response.json();
    })
    .then((data) => {
      if (data.educator) {
        if (data.educator.display_name)setDisplayName(data.educator.display_name)
        if (data.educator.ed_email)setUserTypeEmail(data.educator.ed_email)
        setPhone(data.educator.phone)
        setCountry(data.educator.country)
        setRegion(data.educator.region)
        setCity(data.educator.city)
      }
      if (data.roles.length) {
        setExperienceRows(data.roles)
      } else {
        setExperienceRows(experienceRows.concat({role:"",years:"", main:true, educator_user_email:userDB.email}))
      }

      if (data.subjects.length) {
        setChecked(checked.concat(data.subjects.map(subject => (
          subject.subject_id
        ))))
      }
      if (data.misc_subjects.length) {
        setMiscSubjects(data.misc_subjects)
      }
    })
    .catch((err) => console.error(err))
  }, [userDB.email, authState])

  function getStepPage(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <div><AddPhoto /><ContactInfo userType="Educator" setDisplayName={setDisplayName} displayName={displayName} email={email} setUserTypeEmail={setUserTypeEmail} userTypeEmail={userTypeEmail} setPhone={setPhone} phone={phone}/></div>;
      case 1:
        return <LocationInfo setCountry={setCountry} country={country} setRegion={setRegion} region={region} setCity={setCity} city={city}/>;
      case 2:
        return <ExperienceInfo setExperienceRows={setExperienceRows} experienceRows={experienceRows} setExperienceError={setExperienceError} experienceError={experienceError}/>;
      case 3:
        return <SubjectInfo setChecked={setChecked} checked={checked} setSubjects={setSubjects} subjects={subjects} setMiscSubjects={setMiscSubjects} miscSubjects={miscSubjects}/>;
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNext = () => {
    const { accessToken } = authState
    if (activeStep === 0 || activeStep === 1) {
      fetch(config.resourceServer + 'api/educators/' + userDB.email, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({display_name:displayName, ed_email:userTypeEmail, phone:phone, country:country, region:region, city:city}),
      })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .catch((error) => console.error('Error:', error))

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else if (activeStep === 2) {
      let error = false

      for (let i=0; i < experienceRows.length; i++) {
        if (!experienceRows[i].role || !experienceRows[i].years) {
          error = true
          setExperienceError(true)
          break
        }
      }

      if (!error) {
        setExperienceError(false)
        fetch(config.resourceServer + "/api/roles/uppost/" + userDB.email, {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({role_list: experienceRows}),
        })
        .then(response => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .catch((error) => console.error('Error:', error))

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    else {
      const checkedSubjects = checked.slice(1).map(item => {
          return {subject_id: item, educator_user_email: userDB.email}
      })
      const misc = miscSubjects.map(item => {
        return {is_misc:true, tier1:item}
      })

      /// post subjects
      fetch(config.resourceServer + "/api/subjects/uppost/" + userDB.email, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({subject_list: checkedSubjects, misc_list: misc}),
      })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .catch((error) => console.error('Error:', error))

      window.location.href = config.hostServer
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className={classes.root}>
        <Typography color="primary" variant="h4" component="h4" className={classes.instructions}>
          {getStepContent(activeStep)}
        </Typography>
      </div>
      {getStepPage(activeStep)}
      <div className={classes.root}>
        <div className={classes.feet}>

          <IconButton className={classes.disableBlueHover}
              color="primary"
              aria-label="upload picture"
              component="span"
              disabled={activeStep === 0}
              onClick={handleBack}>
            <ArrowBackIosIcon />
          </IconButton>

          <IconButton className={classes.disableBlueHover}
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleNext}>
            <ArrowForwardIosIcon />
          </IconButton>

          </div>
      </div>
    </div>
  );
}

export default EducatorProgressStepper
