import React, { useEffect, useContext, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import config from '../utils/config';
import { UserContext } from './global/UserContext'
import SuggestedEdits from './SuggestedEdits'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Image } from 'react-native';
import Favorited from './Favorited'
import Notified from './Notified'
import defaultImg from '../images/base/default_img_icon.jpg'
import Review from './Review'
import 'date-fns';
import ProgramForm from './ProgramForm'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      marginBottom: "20px",
      flexGrow: 1
    },
    defaultTab:{
      color: 'white',
      backgroundColor: theme.palette.primary.main
     },
    descriptionSpacing: {
      marginBottom: "5px"
    },
    reviewSpacing: {
      marginRight: "10px"
    },
  }))

const Program = () => {
    const classes = useStyles()
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const { authState, authService } = useOktaAuth();
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [openSuggestion, setSuggestionOpen] = useState(false);
    const [parentReviewsArray, setParentReviewsArray] = useState([]);
    const [eduReviewsArray, setEduReviewsArray] = useState([]);
    const [safeRating, setSafeRating] = useState(0);
    const [recommendationRating, setRecommendationRating] = useState(0);
    const [valueRating, setValueRating] = useState(0);
    const [engagedRating, setEngagedRating] = useState(0);
    const [knowledgeableRating, setKnowledgeableRating] = useState(0);
    const [appropriateRating, setAppropriateRating] = useState(0);
    const [parentReviewExists, setParentReviewExists] = useState(0)
    const [eduReviewExists, setEduReviewExists] = useState(0)
    const [reviewID, setReviewID] = useState(0)
    const { userDB } = useContext(UserContext)
    const [program, setProgram] = useState(0);
    const [company, setCompany] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [parentRating, setParentRating] = useState(null)
    const [educatorRating, setEducatorRating] = useState(null)
    const [isOwner, setIsOwner] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [showLikesNotifications, setShowLikesNotifications] = useState(false)
    const [openSuggestedEdits, setSuggestedEditsOpen] = useState(false)
    const [programType, setProgramType] = useState('');
    const [scholarshipAvailable, setScholarshipAvailable] = useState(null);
    const [remote, setRemote] = useState(null);
    const [country, setCountry] = useState('')
    const [region, setRegion] = useState('')
    const [name, setName] = useState('')
    const [city, setCity] = useState('')
    const [startCost, setStartCost] = useState(null)
    const [endCost, setEndCost] = useState(null)
    const [startGrade, setStartGrade] = useState(null)
    const [endGrade, setEndGrade] = useState(null)
    const [established, setEstablished] = useState('')
    const [mission, setMission] = useState('')
    const [description, setDescription] = useState('')
    const [website, setWebsite] = useState('')
    const [image, setImage] = useState(null)
    const [editComplete, setEditComplete] = useState(false)

    const handleReviewChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const handleDetailsChange = panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickSuggestionOpen = () => {
      setSuggestionOpen(true);
    };

    const handleCloseSuggestion = () => {
      setSuggestionOpen(false);
    };

    const handleClickSuggestedEditsOpen = () => {
      setSuggestedEditsOpen(true);
    };

    const handleCloseSuggestedEdits = () => {
      setSuggestedEditsOpen(false);
    };

    const handleProgramDetails = (data) => {
      setProgramType(data.type)
      setRemote(data.remote)
      setScholarshipAvailable(data.scholarship)
      setRegion(data.state)
      setStartDate(data.start ? new Date(data.start).toLocaleDateString("en-US", {timeZone: "Europe/London"}) : null)
      setEndDate(data.end ? new Date(data.end).toLocaleDateString("en-US", {timeZone: "Europe/London"}) : null)
      setName(data.name)
      setCity(data.city)
      setCountry(data.country)
      setStartCost(data.start_cost === -1 ? null : data.start_cost)
      setEndCost(data.end_cost === -1 ? null : data.end_cost)
      setStartGrade(data.start_grade)
      setEndGrade(data.end_grade)
      setEstablished(data.established ? new Date(data.established).toLocaleDateString("en-US", {timeZone: "Europe/London"}) : null)
      setMission(data.mission)
      setDescription(data.description)
      setWebsite(data.website)
    }

    const handleEditing = () => {
      setIsEditing(true);
    }

    const getReviews = () => {
      fetch(config.resourceServer + "/api/programs/" + window.location.pathname.split("/").pop())
      .then(response => response.json())
      .then((data) => {
        setParentReviewsArray(data.parent_reviews)
        setEduReviewsArray(data.edu_reviews)
        setParentRating(data.program.parent_review)
        setEducatorRating(data.program.educator_review)
      })
      .catch(err => console.error(err) );
    }

    const addSuggestion = (suggestion) => {
      if (authState.isAuthenticated) {
        const { accessToken } = authState
        fetch(config.resourceServer + "/api/programs/" + window.location.pathname.split("/").pop() + '/edits', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({'description': suggestion}),
        })
        .then(response => {
          if (!response.ok) {
          return Promise.reject();
          }
          return response.json();
        })
        .catch(err => {
            console.error(err);
        });
      }
    }

    const addParentReview = (description, engaged, safe, value, recommend) => {
      let parentMethod = parentReviewExists ? 'PUT' : 'POST'
      let endPoint = parentReviewExists ? config.resourceServer + "api/reviews/" + reviewID : config.resourceServer + "api/reviews"
      if (authState.isAuthenticated) {
        const { accessToken } = authState
        authService.getUser().then(info => {
          fetch(endPoint, {
            method: parentMethod,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({'user_email': info.email, 'program_id': window.location.pathname.split("/").pop(), 'description': description,
                                  'educator_review': false, 'recommend': recommend, 'value': value,
                                  'engaged': engaged, 'safe':safe, 'date': new Date().toLocaleDateString("en-US", {timeZone: "Europe/London"})}),
          })
          .then(response => {
            if (!response.ok) {
            return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            if (data){
              setReviewID(data.user_review.review_id)
            }
            setParentReviewExists(true)
            getReviews()
          })
          .catch(err => {
              console.error(err);
          });
        })
      }
    }

    const addEduReview = (description, appropriate, knowledgeable, value, recommend) => {
      let eduMethod = eduReviewExists ? 'PUT' : 'POST'
      let endPoint = eduReviewExists ? config.resourceServer + "api/reviews/" + reviewID : config.resourceServer + "api/reviews"
      if (authState.isAuthenticated) {
        const { accessToken } = authState
        authService.getUser().then(info => {
          fetch(endPoint, {
            method: eduMethod,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({'user_email': info.email, 'program_id': window.location.pathname.split("/").pop(), 'description': description,
                                  'educator_review': true, 'recommend': recommend, 'value': value,
                                  'appropriate': appropriate, 'knowledgeable': knowledgeable,
                                  'date': new Date().toLocaleDateString("en-US", {timeZone: "Europe/London"})}),
          })
          .then(response => {
            if (!response.ok) {
            return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            if (data){
              setReviewID(data.user_review.review_id)
            }
            setEduReviewExists(true)
            getReviews()
          })
          .catch(err => {
              console.error(err);
          });
        })
      }
    }

    const location = () => {
      return([city, region, country].filter(val => val !== '').join(', '))
    } 

    useEffect(() => {
      document.title = 'Malleable Minds • Program'

      const { accessToken } = authState
      if (authState.isAuthenticated && userDB.current_account !== null) {
        authService.getUser().then(info => {
          fetch(config.resourceServer + '/api/reviews/exists/' + info.email + '/' + window.location.pathname.split("/").pop() + '/' + userDB.current_account, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(response => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            if (userDB.current_account === 0) {
              setParentReviewExists(data.response)
            }
            else if (userDB.current_account === 1) {
              setEduReviewExists(data.response)
            }
            if (data.review_id) {
              setReviewID(data.review_id)
            }
          })
          .catch((err) => {
            console.error(err);
          });
        })
      };

      fetch(config.resourceServer + "/api/programs/" + window.location.pathname.split("/").pop())
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setProgram(data.program)
        if (data.program.main_picture && data.program.main_picture !== ""){
          setImage(config.resourceServer + 'api/images/' + data.program.main_picture)
        }
        setCompany(data.company)
        setParentReviewsArray(data.parent_reviews)
        setEduReviewsArray(data.edu_reviews)
        setParentRating(data.program.parent_review)
        setEducatorRating(data.program.educator_review)
        handleProgramDetails(data.program)

        if (authState.isAuthenticated && data.company.id) {
          authService.getUser().then(info => {
            fetch(config.resourceServer + '/api/providers/' + info.email + '/' + data.company.id, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then(response => {
              if (!response.ok) {
                return Promise.reject();
              }
              return response.json();
            })
            .then((data) => {

              if (userDB.current_account === 2) {
                setIsOwner(data.isOwner === 1 && data.verified ? true : false)
              }
              else {
                setIsOwner(false)
              }
            })
            .catch((err) => {
              console.error(err);
            });
          })
        }
        else {
          setIsOwner(false)
        }
      })
      .catch(err => console.error(err) )

      if (authState.isAuthenticated && (userDB.current_account === 0 || userDB.current_account === 1)) setShowLikesNotifications(true)
      else setShowLikesNotifications(false)
    }, [userDB.current_account, authService, authState, editComplete])

    return (
      <div>
        {isEditing &&
        <div>
        <ProgramForm
          editComplete={editComplete} setEditComplete={setEditComplete}
          image={image} setImage={setImage}
          isEditing={isEditing} setIsEditing={setIsEditing}
          company={company} setCompany={setCompany}
          program={program} setProgram={setProgram}
        />
        </div>
        }
        {!isEditing &&
        <div>
          <Image style={{width:'100%', height:300}} source={image ? image : defaultImg} alt="Program"/>
          <Grid container direction="row" justify="space-between" className={classes.root} alignItems="center" spacing={16}>
            <Grid container direction="column" sm={8}>
              {!isEditing &&
              <div>
                <Typography color='primary' inline variant="h4"align="left">
                  <Box fontWeight="fontWeightBold">{name}</Box>
                </Typography>
                <Typography color='secondary' inline variant="body1" align="left">
                  <Box fontWeight="fontWeightBold">{company.name}</Box>
                </Typography>
                <Typography color='primary' inline variant="body1" align="left">
                  <Box fontWeight="fontWeightBold">{location()}</Box>
                </Typography>
              </div>
              }
            </Grid>
            <Grid container direction="column" sm={3} align="right">
              <Grid container direction="row" justify="center">
                { showLikesNotifications &&
                  <div>
                    <Favorited id={program.id} name={name} />
                    <Notified id={program.id} name={name} />
                  </div>
                }
              </Grid>
              <Grid container justify='center'>
                {!isOwner && authState.isAuthenticated &&
                <div>
                  <Button variant="contained" onClick={handleClickSuggestionOpen}>Suggest an Edit</Button>
                  <Dialog open={openSuggestion} onClose={handleCloseSuggestion} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" >
                      <Typography variant="h6" color="primary">Edit Suggestion for {name}</Typography>
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="suggestion"
                        label="Suggestion"
                        multiline
                        fullWidth
                        rows={8}
                        variant="outlined"al
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseSuggestion} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={() => {handleCloseSuggestion(); addSuggestion(document.getElementById('suggestion').value)}} color="primary">
                        Submit Suggestion
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                }
                {isOwner && authState.isAuthenticated &&
                <Grid container justify='center'>
                  <Button variant="contained" onClick={handleEditing}>Edit Program</Button>
                  <Button style={{marginTop: '10px'}} variant="contained" onClick={handleClickSuggestedEditsOpen}>Suggested Edits</Button>
                  <Dialog open={openSuggestedEdits} onClose={handleCloseSuggestedEdits} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" >
                      <Typography variant="h6" color="primary">Suggested Edits for {name}</Typography>
                    </DialogTitle>
                    <DialogContent>
                      <SuggestedEdits id={program.id}/>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseSuggestedEdits} color="primary">
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.root} variant="middle"/>
          <div>
              <Grid className={classes.descriptionSpacing} container justify="space-between">
                <Typography color='secondary' inline variant="body1" align="left">
                  <Box fontWeight="fontWeightBold">Grade Level</Box>
                </Typography>
                {!isEditing &&
                <div>
                  <Typography color='primary' inline variant="body1" align="right">
                    <Box fontWeight="fontWeightBold">
                      {function() {
                        let grades = ''
                        if (startGrade !== null && endGrade !== null) {
                          if (startGrade !== 0 && endGrade !== 0) {
                            if (startGrade === 1) {
                              grades += 'K'
                            }
                            else{
                              grades += (startGrade-1)
                            }
                            if (startGrade !== endGrade) {
                              if (endGrade === 1) {
                                grades += 'K'
                              }
                              else{
                                grades += '-' + (endGrade-1)
                              }
                            }
                          }
                          else if (startGrade !== 0 && endGrade === 0) {
                            if (startGrade === 1) {
                              grades = 'K'
                            }
                            else{
                              grades = (startGrade-1)
                            }
                          }
                          else if (startGrade === 0 && endGrade !== 0) {
                            if (endGrade === 1) {
                              grades = 'K'
                            }
                            else{
                              grades = (endGrade-1)
                            }
                          }
                        }
                        else {
                          grades = '-'
                        }
                        return(
                          <Box fontWeight="fontWeightBold">
                            {grades}
                          </Box>
                        )
                      }}
                    </Box>
                  </Typography>
                </div>
                }
              </Grid>
              <Grid className={classes.descriptionSpacing} container justify="space-between">
                <Typography color='secondary' inline variant="body1" align="left">
                  <Box fontWeight="fontWeightBold">Program Type</Box>
                </Typography>
                {!isEditing &&
                <div>
                  <Typography color='primary' inline variant="body1" align="right">
                    <Box fontWeight="fontWeightBold">{programType === null ? '-' : programType}</Box>
                  </Typography>
                </div>
                }
              </Grid>
              <Grid className={classes.descriptionSpacing} container justify="space-between">
                <Typography color='secondary' inline variant="body1" align="left">
                  <Box fontWeight="fontWeightBold">Cost</Box>
                </Typography>
                {!isEditing &&
                <div>
                  <Typography color='primary' inline variant="body1" align="right">
                  <Box fontWeight="fontWeightBold">{startCost !== null && endCost !== null ? "$"+startCost+"-"+endCost
                      : startCost !== null ? "$"+startCost : endCost !== null ? "$"+endCost : "-"}</Box>
                  </Typography>
                </div>
                }
              </Grid>
              <Grid className={classes.descriptionSpacing} container justify="space-between">
                <Typography color='secondary' inline variant="body1" align="left">
                  <Box fontWeight="fontWeightBold">Scholarship Available</Box>
                </Typography>
                {!isEditing &&
                <div>
                  <Typography color='primary' inline variant="body1" align="right">
                    <Box fontWeight="fontWeightBold">{scholarshipAvailable === null ? '-' : (scholarshipAvailable ? "Yes" : "No")}</Box>
                  </Typography>
                </div>
                }
              </Grid>
              <Grid className={classes.descriptionSpacing} container justify="space-between">
                <Typography color='secondary' inline variant="body1" align="left">
                  <Box fontWeight="fontWeightBold">Remote</Box>
                </Typography>
                {!isEditing &&
                <div>
                  <Typography color='primary' inline variant="body1" align="right">
                    <Box fontWeight="fontWeightBold">{remote === null ? '-' : (remote ? "Yes" : "No")}</Box>
                  </Typography>
                </div>
                }
              </Grid>
          </div>
          <div className={classes.root}>
              <ExpansionPanel style={{width:"100%"}} expanded={expanded === 'panel1'} onChange={handleDetailsChange('panel1')}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color='secondary' variant="body1">
                  <Box fontWeight="fontWeightBold">More Details</Box>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container direction="column">

                    <Typography color='secondary' inline variant="body1" align="left">
                      <Box fontWeight="fontWeightBold">Program Duration: </Box>
                    </Typography>
                    {!isEditing &&
                    <div>
                      <Typography color='primary' inline variant="body1" align="left">
                        <Box fontWeight="fontWeightBold">
                          {function() {
                            if (startDate && endDate) {
                              if (new Date(startDate).getTime() === new Date(endDate).getTime()) {
                                return(<Box fontWeight="fontWeightBold">{new Date(startDate).toLocaleDateString("en-US", {timeZone: "Europe/London"})}</Box>)
                              }
                              else {
                                return(<Box fontWeight="fontWeightBold">{new Date(startDate).toLocaleDateString("en-US", {timeZone: "Europe/London"}) + "-" + new Date(endDate).toLocaleDateString("en-US", {timeZone: "Europe/London"})}</Box>)
                              }
                            }

                            else if (endDate) {
                              return(<Box fontWeight="fontWeightBold">{new Date(endDate).toLocaleDateString("en-US", {timeZone: "Europe/London"})}</Box>)
                            }

                            else {
                              return(<Box fontWeight="fontWeightBold">N/A</Box>)
                            }
                          }}
                        </Box>
                      </Typography>
                    </div>
                    }
                    <Typography color='secondary' inline variant="body1" align="left" style={{marginTop:10}}>
                      <Box fontWeight="fontWeightBold">Establishment Date: </Box>
                    </Typography>
                    {!isEditing &&
                    <div>
                      <Typography color='primary' inline variant="body1" align="left">
                        <Box fontWeight="fontWeightBold">{established ? new Date(established).toLocaleDateString("en-US", {timeZone: "Europe/London"}) : 'N/A'}</Box>
                      </Typography>
                    </div>
                    }
                    <Typography color='secondary' inline variant="body1" align="left" style={{marginTop:10}}>
                      <Box fontWeight="fontWeightBold">Mission: </Box>
                    </Typography>
                    {!isEditing &&
                    <div>
                    <Typography color='primary' inline variant="body1" align="left">
                      <Box fontWeight="fontWeightBold">{mission ? mission : 'N/A'}</Box>
                    </Typography>
                    </div>
                    }
                    <Typography color='secondary' inline variant="body1" align="left" style={{marginTop:10}}>
                      <Box fontWeight="fontWeightBold">Description: </Box>
                    </Typography>
                    {!isEditing &&
                    <div>
                    <Typography color='primary' inline variant="body1" align="left">
                      <Box fontWeight="fontWeightBold">{description ? description : 'N/A'}</Box>
                    </Typography>
                    </div>
                    }
                    <Typography color='secondary' inline variant="body1" align="left" style={{marginTop:10}}>
                      <Box fontWeight="fontWeightBold">Website: </Box>
                    </Typography>
                    {!isEditing &&
                    <div>
                    <Typography color='primary' inline variant="body1" align="left">
                      <Box fontWeight="fontWeightBold">{website ? <a style={{color:'inherit'}} href={website}>{website}</a> : 'N/A'}</Box>
                    </Typography>
                    </div>
                    }
                </Grid>
              </ExpansionPanelDetails>
              </ExpansionPanel>
          </div>
        </div>
        }
        <Divider className={classes.root} variant="middle"/>
        <div>
            <AppBar position="static" color="default">
                <Tabs
                value={value}
                onChange={handleReviewChange}
                indicatorColor="primary"
                textColor="white"
                variant="fullWidth"
                >
                    <Tab className={!value && classes.defaultTab} label="Parent Rating" icon={<Rating name="rating" value={parentRating} precision={0.5} readOnly/>}/>
                    <Tab className={value && classes.defaultTab} label="Educator Rating" icon={<Rating name="rating" value={educatorRating} precision={0.5} readOnly/>}/>
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Grid container justify="space-between">
                    <div>
                      <Typography variant="h6" color="primary">Reviews</Typography>
                    </div>
                    <div>
                      {userDB && userDB.current_account === 0 && parentReviewExists ?
                      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Update Review
                      </Button> : userDB && userDB.current_account === 0 ?
                      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Write a Review
                      </Button> : null
                      }
                      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" >
                          <Typography variant="h6" color="primary">Review for {name}</Typography>
                        </DialogTitle>
                        <DialogContent>
                          <Grid className={classes.reviewSpacing} container justify="space-between">
                            <Rating name="engaged" className={classes.reviewSpacing} precision={0.5} onChange={(_, value) => {setEngagedRating(value);}} />
                            <Typography inline variant="body1" color="secondary" align="right">Positively Engaged Child</Typography>
                          </Grid>
                          <Grid className={classes.reviewSpacing} container justify="space-between">
                            <Rating name="safe" className={classes.reviewSpacing} precision={0.5} onChange={(_, value) => {setSafeRating(value);}} />
                            <Typography inline variant="body1" color="secondary" align="right">Safe</Typography>
                          </Grid>
                          <Grid className={classes.reviewSpacing} container justify="space-between">
                            <Rating name="value" className={classes.reviewSpacing} precision={0.5} onChange={(_, value) => {setValueRating(value);}} />
                            <Typography inline variant="body1" color="secondary" align="right">Value</Typography>
                          </Grid>
                          <Grid className={classes.reviewSpacing} container justify="space-between">
                            <Rating name="recommend" className={classes.reviewSpacing} precision={0.5} onChange={(_, value) => {setRecommendationRating(value);}} />
                            <Typography inline variant="body1" color="secondary" align="right">Recommend to Others</Typography>
                          </Grid>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label="Review"
                            multiline
                            fullWidth
                            rows={4}
                            variant="outlined"al
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} color="primary">
                            Cancel
                          </Button>
                          <Button onClick={() => {handleClose(); addParentReview(document.getElementById('description').value, engagedRating,
                                                                           safeRating, valueRating, recommendationRating)}} color="primary">
                            Post Review
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </Grid>
                  {
                  parentReviewsArray.map((review) => (
                    <Review review={review} isOwner={isOwner}/>
                  ))
                  }
                  {!parentReviewsArray.length &&
                  <Typography>No Reviews Yet!</Typography>}
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Grid container justify="space-between">
                    <div>
                      <Typography variant="h6" color="primary">Reviews</Typography>
                    </div>
                    <div>
                      {userDB && userDB.educator_verified && userDB.current_account === 1 && eduReviewExists ?
                      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Update Review
                      </Button> : userDB && userDB.educator_verified && userDB.current_account === 1 ?
                      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Write a Review
                      </Button> : null
                      }
                      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" >
                          <Typography variant="h6" color="primary">Review for {name}</Typography>
                        </DialogTitle>
                        <DialogContent>
                          <Grid className={classes.reviewSpacing} container justify="space-between">
                            <Rating name="engaged" className={classes.reviewSpacing} precision={0.5} onChange={(_, value) => {setAppropriateRating(value);}} />
                            <Typography inline variant="body1" color="secondary" align="right">Appropriate for Age</Typography>
                          </Grid>
                          <Grid className={classes.reviewSpacing} container justify="space-between">
                            <Rating name="safe" className={classes.reviewSpacing} precision={0.5} onChange={(_, value) => {setKnowledgeableRating(value);}} />
                            <Typography inline variant="body1" color="secondary" align="right">Knowledgeable Staff</Typography>
                          </Grid>
                          <Grid className={classes.reviewSpacing} container justify="space-between">
                            <Rating name="value" className={classes.reviewSpacing} precision={0.5} onChange={(_, value) => {setValueRating(value);}} />
                            <Typography inline variant="body1" color="secondary" align="right">Value</Typography>
                          </Grid>
                          <Grid className={classes.reviewSpacing} container justify="space-between">
                            <Rating name="recommend" className={classes.reviewSpacing} precision={0.5} onChange={(_, value) => {setRecommendationRating(value);}} />
                            <Typography inline variant="body1" color="secondary" align="right">Recommend to Others</Typography>
                          </Grid>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label="Review"
                            multiline
                            fullWidth
                            rows={4}
                            variant="outlined"al
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} color="primary">
                            Cancel
                          </Button>
                          <Button onClick={() => {handleClose(); addEduReview(document.getElementById('description').value, appropriateRating,
                                                                           knowledgeableRating, valueRating, recommendationRating)}} color="primary">
                            Post Review
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </Grid>
                  {
                  eduReviewsArray.map((review) => (
                    <Review review={review} isOwner={isOwner}/>
                  ))
                  }
                  {!eduReviewsArray.length &&
                  <Typography>No Reviews Yet!</Typography>}
                </TabPanel>
            </SwipeableViews>
        </div>
      </div>
    )
  }

export default Program
