import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import InputLabel from '@material-ui/core/InputLabel';
import {useStyles} from '../../utils/css.js'

const LocationInfo = (props) => {
  const classes = useStyles()
  const [ country, setCountry ] = useState(props.country)
  const [ region, setRegion ] = useState(props.region)

  const selectCountry = (val) => {
    props.setCountry(val)
    setCountry(val)
  }

  const selectRegion = (val) => {
    props.setRegion(val)
    setRegion(val)
  }

  return (

      <div className={classes.flexCenter}>
        <form noValidate autoComplete="off">
        <div>
          <InputLabel className={classes.textField + ' ' + classes.width40ch} id={"country"}>Country</InputLabel>
          <CountryDropdown
            defaultOptionLabel=""
            className={classes.dropdownFieldApi}
            value={country}
            onChange={(val) => selectCountry(val)} />
        </div>
        <div>
          <InputLabel className={classes.textField + ' ' + classes.width40ch} id={"region"}>Region</InputLabel>
          <RegionDropdown
            defaultOptionLabel=""
            className={classes.dropdownFieldApi}
            country={country}
            value={region}
            onChange={(val) => selectRegion(val)} />
        </div>
        <div>
          <TextField
            className={classes.textField + ' ' + classes.width40ch}
            id="city"
            label="City"
            value={props.city || ''}
            onChange={(e) => props.setCity(e.target.value)}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              }
            }}
          />
        </div>
        </form>
      </div>

  )
}

export default LocationInfo
