// https://material-ui.com/components/grid-list/
import React, {useState, useEffect, useContext} from 'react';
import IconButton from '@material-ui/core/IconButton'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { makeStyles } from '@material-ui/core/styles'
import { UserContext } from './global/UserContext'
import { useOktaAuth } from '@okta/okta-react'

import config from '../utils/config.js'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary['400'],
  },
  icon: {
    "&:focus-within": {
      outline: "none",
    },

  }
}))

const Favorited = (props) =>  {
  const [ favorited, setFavorited ] = useState(null)
  const { userDB } = useContext(UserContext)
  const { authState } = useOktaAuth()
  const classes = useStyles()

  useEffect(() => {
    if (authState.isAuthenticated && props.id) {
      const urlLoc = userDB.current_account === 0 ? "parent/" : userDB.current_account === 1 ? "educator/" : null
      if (urlLoc) {
        const { accessToken } = authState
        fetch(config.resourceServer + "api/likes/" + urlLoc + props.id + "/" + userDB.email, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(response => response.json())
        .then((data) => {
          setFavorited(data.program_like !== null)
        })
        .catch(err => console.error(err))
      }
    }
  }, [userDB, authState, props.id])

  const postFavorited = () => {
    const urlLoc = userDB.current_account === 0 ? "parent/" : userDB.current_account === 1 ? "educator/" : null
    if (urlLoc) {
      const { accessToken } = authState
      fetch(config.resourceServer + "api/likes/" + urlLoc + props.id + "/" + userDB.email, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        }
      })
      .then(response => {
        response.json();
        setFavorited(!favorited)
      })
      .catch(err => console.error(err))
    }
  }

  return (
      <IconButton className={props.style ? props.style : classes.icon} aria-label={`star ${props.name}`} onClick={()=>postFavorited()}>
        {favorited && <FavoriteIcon className={classes.title} fontSize={props.size}/>}
        {!favorited && <FavoriteBorderIcon className={classes.title} fontSize={props.size}/>}
      </IconButton>
  );
}

export default Favorited
