//LOCAL CONFIGURATION
// const CLIENT_ID = '0oawejwjzHPteGpX54x6';
// const ISSUER = 'https://dev-905376.okta.com/oauth2/default';
// const OKTA_TESTING_DISABLEHTTPSCHECK = false;
// const HOST_SERVER = 'http://localhost:3000/'
// const CALLBACK_SERVER = 'http://localhost:3000/callback'
// const RESOURCE_SERVER = 'http://localhost:5000/'

//HOSTED CONFIGURATION
const CLIENT_ID = '0oa175iop4RLxt2Ce4x7';
const ISSUER = 'https://dev-905376.okta.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const HOST_SERVER = 'https://malleable-minds.com/'
const CALLBACK_SERVER = 'https://malleable-minds.com/callback'
const RESOURCE_SERVER = 'https://api.malleable-minds.com/'

export default {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: CALLBACK_SERVER,
    hostServer: HOST_SERVER,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    resourceServer: RESOURCE_SERVER
};
