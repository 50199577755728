import React from 'react'
import TextField from '@material-ui/core/TextField'
import {useStyles} from '../../utils/css.js'

const ContactInfo = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.flexCenter}>
      <form noValidate autoComplete="off" align='center'>
        <div style={{display:'flex'}}>
          <div>
            <TextField
              className={classes.textField + ' ' + classes.width35ch}
              id="name"
              value={props.displayName || ''}
              label="Public Display Name"
              onChange={(e)=>props.setDisplayName(e.target.value)}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                }
              }}
            />
          </div>
          <div>
            <TextField
              className={classes.textField + ' ' + classes.width35ch}
              id="phone"
              value={props.phone || ''}
              label="Phone Number"
              onChange={(e)=>props.setPhone(e.target.value)}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                }
              }}
            />
          </div>
        </div>
        <div style={{display:'flex'}}>
          <div>
            <TextField
              className={classes.textField + ' ' + classes.width35ch}
              id="primaryEmail"
              label="Primary Email"
              defaultValue={props.email}
              disabled={true}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                }
              }}
            />
          </div>
          <div>
            <TextField
              className={classes.textField + ' ' + classes.width35ch}
              id="email"
              label={props.userType + " Email"}
              value={props.userTypeEmail || ''}
              onChange={(e)=>props.setUserTypeEmail(e.target.value)}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                }
              }}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactInfo
