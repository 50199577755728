import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#833ab4', 400: '#d1a3d9'},
    secondary: { A400:'#460e56'},
    grey: {100:'#f6f6f6', 200:'#edededdd', 300:'#ededed'}
  },
  status: {
    danger: 'orange'
  }
})

export default theme
