import React, {useContext} from 'react'
import { UserContext } from '../global/UserContext'
import EducatorProgressStepper from './EducatorProgressStepper'
import ParentProgressStepper from './ParentProgressStepper'
import ProviderProgressStepper from './ProviderProgressStepper'

const ChooseRegisterPage = () => {
  const { userDB } = useContext(UserContext)

  const userStepper = (userIndex) => {
    switch (userIndex) {
      case 0:
        return <ParentProgressStepper />;
      case 1:
        return <EducatorProgressStepper />;
      case 2:
        return <ProviderProgressStepper />;
      default:
        return '';
    }
  }

  return (
    <div>
    {userStepper(userDB.current_account)}
    </div>
  )
}

export default ChooseRegisterPage
