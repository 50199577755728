import React from 'react'
import {useStyles} from '../../utils/css.js'

const Footer = () => {
const classes = useStyles()

return <div className={classes.margin10}></div>

}

export default Footer
