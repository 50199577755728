// https://material-ui.com/components/text-fields/
// add error text fields on save
import Avatar from '@material-ui/core/Avatar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useState, useEffect, useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react'
import { UserContext } from '../global/UserContext'
import config from '../../utils/config.js'
import Rating from '@material-ui/lab/Rating';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    justifyContent: 'center',
  },
  defaultTab:{
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  medium: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  },
  reviewBottom: {
    marginBottom: "15px"
  },
  reviewRating: {
    paddingTop: "3px",
    paddingLeft: "10px"
  },
  topElementPadding: {
    paddingTop: "10px",
    paddingLeft: "10px"
  },
  bottomElementPadding: {
    paddingBottom: "10px",
    paddingTop: "3px",
    paddingLeft: "10px"
  },
  topMargin: {
    marginTop: theme.spacing(5),
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EducatorPublicProfile = () => {
    const { userDB } = useContext(UserContext)
    const { authState, authService } = useOktaAuth()
    const classes = useStyles()
    const theme = useTheme();
    const [buttonText, setButtonText] = useState("Follow");
    const [buttonColor, setButtonColor] = useState("primary");
    const [reviewsArray, setReviewsArray] = useState([]);
    const [eduInfo , setEduInfo] = useState([]);
    const [value, setValue] = useState(0);
    const [subjects, setSubjects] = useState([]);
    const [roles, setRoles] = useState([]);
    const [currentRole, setCurrentRole] = useState(null);

    const handleFollowChange = (text) => {
      if (text === 1) {
        setButtonText('Following')
        setButtonColor('default')
      }

      else {
        setButtonText('Follow')
        setButtonColor('primary')
      }

      if (authState.isAuthenticated) {
        const { accessToken } = authState
        const userType = userDB.current_account === 0 ? 'parents' : 'educators'
        authService.getUser().then(info => {
        fetch(config.resourceServer + '/api/follows/' + userType + '/' + info.email + '/' + eduInfo.id, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            }
        })
        .then(response => {
            if (!response.ok) {
            return Promise.reject();
            }
            return response.json();
        })
        .catch(err => {
            console.error(err);
        });
        })
      }
    }

    const handleEducatorTabsChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const review = (reviewNum) =>
    <div>
      <Typography variant="h5" color="primary">
        Review for {<Typography variant="h5" color="primary" component={Link} to={"/program/" + reviewsArray[reviewNum].program_id}>{reviewsArray[reviewNum].program_name}</Typography>}
      </Typography>
      <div className={classes.reviewBottom}>
        <Box fontStyle="italic">
          <Typography variant="h8" color="secondary">
            {reviewsArray[reviewNum].review.date !== null && new Date(reviewsArray[reviewNum].review.date).toLocaleDateString("en-US", {timeZone: "Europe/London"})}
          </Typography>
        </Box>
      </div>
      <AppBar className={classes.reviewBottom} position="static">
        <Grid className={classes.topElementPadding} container>
          <Rating className={classes.topElementPadding} name="Engagement Rating" precision={0.5} value={reviewsArray[reviewNum].edu_review.appropriate} readOnly />
          <Typography className={classes.topElementPadding} inline variant="body1">Appropriate for Age</Typography>
        </Grid>
        <Grid className={classes.reviewRating} container>
          <Rating className={classes.reviewRating} name="Safe Rating" precision={0.5} value={reviewsArray[reviewNum].edu_review.knowledgeable} readOnly />
          <Typography className={classes.reviewRating} inline variant="body1">Knowledgeable Staff</Typography>
        </Grid>
        <Grid className={classes.reviewRating} container>
          <Rating className={classes.reviewRating} name="Value Rating" precision={0.5}  value={reviewsArray[reviewNum].review.value} readOnly />
          <Typography className={classes.reviewRating} inline variant="body1">Value</Typography>
        </Grid>
        <Grid className={classes.bottomElementPadding} container>
          <Rating className={classes.bottomElementPadding} name="Recommendation Rating" precision={0.5}  value={reviewsArray[reviewNum].review.recommend} readOnly />
          <Typography className={classes.bottomElementPadding} inline variant="body1">Recommend to Others</Typography>
        </Grid>
      </AppBar>
      <Typography className={classes.reviewBottom}>{reviewsArray[reviewNum].review.description}</Typography>
      {reviewNum !== (reviewsArray.length-1) &&
      <Divider className={classes.root} style={{marginBottom:'10px'}} variant="middle"/>}
    </div>


    const role = (roleNum) =>
    <div style={{marginTop:'15px'}}>
      <Typography color='secondary' inline variant="h6">
        <Box fontWeight="fontWeightBold">{roles[roleNum].role}</Box>
      </Typography>
      <Typography variant="h7">{roles[roleNum].years === '1' ? 'Less than 1 year' :
                   roles[roleNum].years === '2' ? '1-3 years' :
                   roles[roleNum].years === '3' ? '4-6 years' :
                   roles[roleNum].years === '4' ? '7-9 years' :
                   '10+ years'}</Typography>
    </div>

    const location = () => {
      return([eduInfo.city, eduInfo.region, eduInfo.country].filter(val => val !== '' && val !== undefined).join(', '))
    } 

    useEffect(() => {
      document.title = 'Malleable Minds • Educator'

      const eduID = window.location.pathname.split("/").pop()
      fetch(config.resourceServer + '/api/unauth/educators/' + eduID)
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setReviewsArray(data.reviews)
        setEduInfo(data.educator)
        setSubjects(data.subjects.concat(data.misc_subjects))
        setRoles(data.roles)
        setCurrentRole(data.main_role)
      })
      .catch((err) => {
        console.error(err);
      });
      if (authState.isAuthenticated) {
        const { accessToken } = authState
        const current = userDB.current_account === 0 ? 'parents' : 'educators'
        authService.getUser().then(info => {
          fetch(config.resourceServer + '/api/follows/' + current + '/' + info.email + '/' + eduID, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            }
          })
          .then(response => {
            if (!response.ok) {
            return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            if ((userDB.current_account == 1 && data.educator_follow !== null) ||
              (userDB.current_account == 0 && data.parent_follow !== null)) {
              setButtonText('Following')
              setButtonColor('default')
            }

            else {
              setButtonText('Follow')
              setButtonColor('primary')
            }

          })
          .catch(err => {
              console.error(err);
          });
        })
      }
    }, [authState, authService, userDB.current_account])

    return (
      <div>
        {eduInfo !== null &&
        <div>
            <div>
                <Grid style={{marginBottom: '10px'}} className={classes.root}>
                    <Grid>
                        <Avatar className={classes.medium} src={config.resourceServer + 'api/images/' + eduInfo.picture} />
                    </Grid>
                    <Grid container direction='column'>
                        <Grid container>
                            <Grid>
                            <Typography color='primary' variant='h4'>{eduInfo.display_name}</Typography>
                            </Grid>
                            {authState.isAuthenticated && userDB.current_account !== 2 && userDB.educator_id !== eduInfo.id &&
                            <Grid style={{marginLeft: '20px'}}>
                                <Button color={buttonColor} className={classes.textUnchanged} disableElevation variant="contained" onClick={() =>
                                    buttonText === "Following" ? handleFollowChange(0) : handleFollowChange(1)}>{buttonText}
                                </Button>
                            </Grid>
                            }
                        </Grid>
                        <Grid>
                            <Typography color='secondary' variant='h6'>{location()}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.root} variant="middle"/>
            <AppBar position="static" color="default" className={classes.topMargin}>
              <Tabs
                  value={value}
                  onChange={handleEducatorTabsChange}
                  indicatorColor="primary"
                  textColor="white"
                  variant="fullWidth"
                >
                <Tab className={value === 0 && classes.defaultTab} label="Reviews"/>
                <Tab className={value === 1 && classes.defaultTab} label="Experience"/>
              </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <div style={{marginTop:'15px'}}>
                    { function() {
                    let rows = []
                    for (let i=0; i < reviewsArray.length; i++) {
                        rows.push(review(i))
                    }
                    return rows
                    }() }
                    {!reviewsArray.length &&
                    <Typography align='center'>No reviews yet!</Typography>}
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Typography align='center' variant="h4" color="primary">Subjects</Typography>
                  <div style={{display:'flex', justifyContent:'center', marginTop:'15px'}}>
                    { function() {
                    let subjectsList = []
                    for (let i=0; i < subjects.length; i++) {
                      subjectsList.push(<Chip style={{marginRight:'5px'}} label={subjects[i]} />)
                    }
                    return subjectsList
                    }() }
                    {!subjects.length &&
                    <Typography align='center'>No subjects available.</Typography>}
                  </div>
                  <Divider style={{marginTop:'15px', marginBottom:'15px'}} variant="middle"/>
                  <Typography  align='center' variant="h4" color="primary">Roles</Typography>
                  {currentRole &&
                  <Grid style={{marginTop:'10px'}} container justify='center'>
                    <Typography variant="h6" color="secondary">
                      <Box fontWeight="fontWeightBold">Current Role:</Box>
                    </Typography>
                    <Typography style={{marginLeft:'5px'}} variant="h6">{currentRole}</Typography>
                  </Grid>
                  }
                  <div>
                    { function() {
                    let rolesList = []
                    for (let i=0; i < roles.length; i++) {
                      rolesList.push(role(i))
                    }
                    return rolesList
                    }() }
                    {!roles.length &&
                    <Typography align='center'>No subjects available.</Typography>}
                  </div>
                </TabPanel>
            </SwipeableViews>
        </div>
        }
      </div>
    )
}

export default EducatorPublicProfile
