import React, {useState, useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Avatar } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import DeleteIcon from '@material-ui/icons/Delete'
import { UserContext } from '../global/UserContext'
import { useOktaAuth } from '@okta/okta-react'
import config from '../../utils/config.js'

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    textUnchanged: {
        textTransform: "none",
        width: "90px"
    },
    button: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      marginTop: theme.spacing(1),
      width: theme.spacing(20)
    }
  }))

const edu_notif = (props, classes, anchorEL, setAnchorEl, setDeleted) => {
  const index = props.notification.edu_message.description.indexOf("for") + 4
  const desc = props.notification.edu_message.description.slice(0,index)
  const programId = props.notification.edu_message.description.slice(index, index+2)
  const program = props.notification.edu_message.description.slice(index+2)

  return (
    <div align='center'>
    <ListItem button alignItems='flex-start' divider={true} style={{width:'60%'}}>
      <div style={{display: 'inline-block'}}>
        <IconButton color='primary'>
          <Avatar src={config.resourceServer + 'api/images/' + props.notification.edu_picture} />
        </IconButton>
      </div>
      <div  style={{display: 'inline-block', marginTop:10, width:'80%'}}>
        <Typography display="inline" color='primary' variant="h5" component="p" component={Link} to={"/educator/" + props.notification.edu_message.educator_id}>
          {props.notification.edu_display_name}
        </Typography>
        <Typography display="inline" variant="h5" component="p">{desc}</Typography>
        <Typography display="inline" color='primary' variant="h5" component="p" component={Link} to={"/program/" + programId}>
          {program}
        </Typography>
      </div>

      {menu(props, classes, anchorEL, setAnchorEl, setDeleted)}

    </ListItem>
    </div>
  )
}

const program_notif = (props, classes, anchorEL, setAnchorEl, setDeleted) => {
  const index = props.notification.description.indexOf(",") + 2
  const index2 = props.notification.description.indexOf(",",index+1)
  const first = props.notification.description.slice(0,index)
  const program = props.notification.description.slice(index,index2)
  const second = props.notification.description.slice(index2)

  return (
    <div align='center'>
    <ListItem button alignItems='flex-start' divider={true} style={{width:'60%'}}>
      <div style={{display: 'inline-block'}}>
        <IconButton color='primary'>
          <NewReleasesIcon fontSize="large"/>
        </IconButton>
      </div>
      <div  style={{display: 'inline-block', marginTop:10, width:'80%'}}>
        <Typography display="inline" variant="h5" component="p">
          {first}
        </Typography>
        <Typography display="inline" color='primary' variant="h5" component="p" component={Link} to={"/program/" + props.notification.program_id}>{program}</Typography>
        <Typography display="inline" variant="h5" component="p">
          {second}
        </Typography>
      </div>

      {menu(props, classes, anchorEL, setAnchorEl, setDeleted)}

    </ListItem>
    </div>
  )
}

const menu = (props, classes, anchorEl, setAnchorEl, setDeleted) => {
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  };

  return (
    <div>
      <IconButton onClick={handleMenuOpen}>
        <MoreHorizIcon fontSize="large"/>
      </IconButton>
      {RenderMenu(anchorEl, setAnchorEl, props.notification.message_id ? props.notification.message_id : props.notification.edu_message.message_id, setDeleted)}
    </div>
  )
}

const RenderMenu = (anchorEl, setAnchorEl, messageId, setDeleted) => {
  const isMenuOpen = Boolean(anchorEl)
  const { userDB } = useContext(UserContext)
  const { authState } = useOktaAuth()

  const onDelete = () => {
    // delete EducatorReceivedMessage or ParentReceivedMessage
    setDeleted(true)
    if (userDB.current_account === 0) {
      const { accessToken } = authState
      fetch(config.resourceServer + "/api/notifications/parent/" + userDB.email, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({message_id:messageId}),
      })
      .then(response => response.json())
      .catch(err => console.error(err) );
    }
    else if (userDB.current_account === 1) {
      const { accessToken } = authState
      fetch(config.resourceServer + "/api/notifications/educator/" + userDB.email, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({message_id:messageId}),
      })
      .then(response => response.json())
      .catch(err => console.error(err) );
    }
  }

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem onClick={onDelete}>
        <DeleteIcon/>
        Delete
      </MenuItem>
    </Menu>
  )
}

const Notification = (props) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [deleted, setDeleted] = useState(false)

    const notif_type = props.type === 'program' ? program_notif(props, classes, anchorEl, setAnchorEl, setDeleted) : edu_notif(props,classes,anchorEl, setAnchorEl, setDeleted)

    return (
      <div>
        {!deleted && notif_type}
      </div>
    )
  }

export default Notification
