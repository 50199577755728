import React, { useContext, useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import ChildInfo from '../formComponents/ChildInfo'
import ContactInfo from '../formComponents/ContactInfo'
import LocationInfo from '../formComponents/LocationInfo'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import IconButton from '@material-ui/core/IconButton'
import AddPhoto from '../formComponents/AddPhoto'
import { UserContext } from '../global/UserContext'
import config from '../../utils/config.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    justifyContent: 'center',
    flexGrow: 1,
  },
  backButton: {
    marginRight: theme.spacing(1),
    justifyContent: 'center'
  },
  instructions: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
  feet: {
    marginTop: theme.spacing(5),
  },
  disableBlueHover: {
    "&:hover": {
      color: theme.palette.primary.light,
    },
  }
}));

function getSteps() {
  return ['Upload Picture & Add Contact Info', 'Enter Location', 'Describe Children'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Tell us about yourself';
    case 1:
      return "What area are you in?";
    case 2:
      return "We'll display programs specific to your child's age group"
    default:
      return '';
  }
}

const ParentProgressStepper = () => {
  const { userDB } = useContext(UserContext)
  const { authState } = useOktaAuth()
  const classes = useStyles();

  // stepper information
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  // parent specific contact fields
  const [ displayName, setDisplayName] = useState(userDB.first_name)
  const [ email ] = useState(userDB.email)
  const [ userTypeEmail, setUserTypeEmail] = useState(userDB.email)
  const [ phone, setPhone ] = useState(null)

  // parent specific location fields
  const [ country, setCountry ] = useState(null)
  const [ region, setRegion ] = useState(null)
  const [ city, setCity ] = useState(null)

  // parent specific child fields
  const [ childRows, setChildRows ] = useState([])
  const [ childError, setChildError ] = useState(false)

  useEffect(() => {
    document.title = 'Malleable Minds • Registration'
    const { accessToken } = authState
    fetch(config.resourceServer + 'api/parents/' + userDB.email, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(response => { return response.json()})
    .then((data) => {
      if (data.parent) {
        if (data.parent.display_name)setDisplayName(data.parent.display_name)
        if (data.parent.par_email)setUserTypeEmail(data.parent.par_email)
        setPhone(data.parent.phone)
        setCountry(data.parent.country)
        setRegion(data.parent.region)
        setCity(data.parent.city)
        setChildRows(data.children)
      }
    })
    .catch((err) => console.error(err))
  }, [userDB.email, authState])

  function getStepPage(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <div><AddPhoto /><ContactInfo userType="Parent" setDisplayName={setDisplayName} displayName={displayName} email={email} setUserTypeEmail={setUserTypeEmail} userTypeEmail={userTypeEmail} setPhone={setPhone} phone={phone}/></div>;
      case 1:
        return <LocationInfo setCountry={setCountry} country={country} setRegion={setRegion} region={region} setCity={setCity} city={city}/>;
      case 2:
        return <ChildInfo setChildRows={setChildRows} childRows={childRows} setChildError={setChildError} childError={childError}/>;
      default:
        return '';
    }
  }

  const handleNext = () => {
    const { accessToken } = authState
    if (activeStep === 0 || activeStep === 1) {
      fetch(config.resourceServer + 'api/parents/' + userDB.email, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({display_name:displayName, par_email:userTypeEmail, phone:phone, country:country, region:region, city:city}),
      })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else {
      let error = false

      for (let i=0; i < childRows.length; i++) {
        if (!childRows[i].name || !childRows[i].grade) {
          error = true
          setChildError(true)
          break
        }
      }

      if (!error) {
        setChildError(false)
        fetch(config.resourceServer + "api/children/uppost/" + userDB.email, {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({child_list: childRows}),
        })
        .then(response => response.json());

        window.location.href = config.hostServer
      }
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <div>
        <Stepper activeStep={activeStep} alternativeLabel style={{background:"rgba(0, 0, 0, 0.001)",}}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className={classes.root}>
        <Typography color="primary" variant="h4" component="h4" className={classes.instructions}>
          {getStepContent(activeStep)}
        </Typography>
      </div>
      {getStepPage(activeStep)}
      <div className={classes.root}>
        <div className={classes.feet}>

          <IconButton className={classes.disableBlueHover}
              color="primary"
              component="span"
              disabled={activeStep === 0}
              onClick={handleBack}>
            <ArrowBackIosIcon />
          </IconButton>

          <IconButton className={classes.disableBlueHover}
            color="primary"
            component="span"
            onClick={handleNext}>
            <ArrowForwardIosIcon />
          </IconButton>

          </div>
      </div>
    </div>
  );
}

export default ParentProgressStepper
