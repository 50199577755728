import React, { useEffect, useContext, useState } from 'react'
import Followee from './Followee'
import { useOktaAuth } from '@okta/okta-react'
import config from '../../utils/config';
import { UserContext } from '../global/UserContext'
import Typography from '@material-ui/core/Typography';

const Following = () => {
    const { authState, authService } = useOktaAuth();
    const { userDB } = useContext(UserContext)
    const [followees, setFollowees] = useState([])

    useEffect(() => {
        document.title = 'Malleable Minds • Following'

        if (authState.isAuthenticated && userDB.current_account !== null) {
            const { accessToken } = authState
            const userType = userDB.current_account == 0 ? 'parents' : 'educators'
            authService.getUser().then(info => {
                fetch(config.resourceServer + '/api/' + userType + '/' + info.email + '/following', {
                    headers: {
                    Authorization: `Bearer ${accessToken}`,
                    },
                })
                .then(response => {
                    if (!response.ok) {
                    return Promise.reject();
                    }
                    return response.json();
                })
                .then((data) => {
                    setFollowees(data.following)
                })
                .catch((err) => {
                    console.error(err);
                });
            })
        };
    }, [authService, authState])

    return (
        <div>
            {followees.map((followee) => (
                <Followee followee={followee}/>
            ))}
            {followees.length == 0 &&
            <Typography align='center' color='secondary' inline variant="h4" fontWeight="bold">
                Once you like a follow an educator, you'll see it here.
            </Typography>
            }
        </div>
    )
}

export default Following
