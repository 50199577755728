import React from 'react';
import { useOktaAuth } from '@okta/okta-react'
import Typography from '@material-ui/core/Typography';
import config from '../utils/config.js'
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const SuggestedEdit = (props) => {
    const edit = props.edit
    const { authState } = useOktaAuth()

    const handleDelete = () => {
        if (authState.isAuthenticated) {
            const { accessToken } = authState
            fetch(config.resourceServer + '/api/programEdits/' + edit.id, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${accessToken}` }
            })
            .then(response => {
                if (!response.ok) {
                return Promise.reject();
                }
                return response.json();
              })
              .then(() => {
                props.setDeleteSuggestion(!props.deleteSuggestion)
              })
            .catch(err => console.error(err));
        }
    };

    return (
        <div>
            <IconButton color="primary" onClick={() => handleDelete()} style={{width:'1px', height:'1px', marginTop:'5px'}}>
                <ClearIcon fontSize='small' />
            </IconButton>
            <Typography align='center' color='secondary' inline variant="body1">
                {edit.description}
            </Typography>
        </div>
    )
}

export default SuggestedEdit
