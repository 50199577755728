// https://material-ui.com/components/text-fields/
// add error text fields on save
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useState, useEffect, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useOktaAuth } from '@okta/okta-react';
import config from '../../utils/config';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import EventIcon from '@material-ui/icons/Event';
import PhoneIcon from '@material-ui/icons/Phone'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Divider from '@material-ui/core/Divider';
import ContactInfo from '../formComponents/ContactInfo'
import LocationInfo from '../formComponents/LocationInfo'
import defaultImg from '../../images/base/default_img_icon.jpg'
import { UserContext } from '../global/UserContext'
import { Image } from 'react-native';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPhoto from '../formComponents/AddPhoto'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    justifyContent: 'center',
    flexGrow: 1,
  },
  medium: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  editBox: {
    position: 'absolute',
    marginTop: theme.spacing(22),
    marginLeft: theme.spacing(18),
  },
  topMargin: {
    marginTop: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(5),
    width: '20ch',
  },
  disableBlueOutline: {
    "&:focus-within": {
      outline: "none"
    },
  },
  alert: {
    '& > * + *': {
      marginTop: theme.spacing(0.25),
    },
    "&:hover": {
      textDecoration: 'none',
    },
    position: 'relative',
    width:'100vw',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    marginTop: '-2.5em',
    marginBottom: '2em'

  },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

const ProviderProfile = () => {
  const { userDB } = useContext(UserContext)
  const { authState, authService } = useOktaAuth()
  const classes = useStyles()
  const theme = useTheme();

  // stepper information
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState(userDB.default_account === 2)

  // primary provider specific contact fields
  const [ displayName, setDisplayName] = useState(userDB.first_name)
  const [ email ] = useState(userDB.email)
  const [ userTypeEmail, setUserTypeEmail] = useState(userDB.email)
  const [ phone, setPhone ] = useState(null)

  // secondary provider specific contact fields
  const [ displayName2, setDisplayName2] = useState(null)
  const [ userTypeEmail2, setUserTypeEmail2] = useState(null)
  const [ phone2, setPhone2 ] = useState(null)

  // provider specific location fields
  const [ country, setCountry ] = useState(null)
  const [ region, setRegion ] = useState(null)
  const [ city, setCity ] = useState(null)

  // provider specific programs
  const [ providerPrograms, setProviderPrograms ] = useState([])
  const [ companyName, setCompanyName ] = useState("")
  const [ companyId, setCompanyId ] = useState(null)
  const [ verified, setVerified ] = useState(false)

  const completeRegistrationAlert = !verified &&
    <div>
      <Alert
        severity="info"
        className={classes.alert}
        >
        You are not yet verified. Once verified you will receive an email.
      </Alert>
      </div>

  useEffect(() => {
    document.title = 'Malleable Minds • Profile'

    if (authState.isAuthenticated) {
      const { accessToken } = authState
      fetch(config.resourceServer + 'api/providers/' + userDB.email, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => {
        if (!response.ok) {
        return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        if (data.provider) {
          if (data.provider.display_name)setDisplayName(data.provider.display_name)
          if (data.provider.pro_email)setUserTypeEmail(data.provider.pro_email)
          setPhone(data.provider.phone)

          setDisplayName2(data.provider.sec_first_name)
          setUserTypeEmail2(data.provider.sec_email)
          setPhone2(data.provider.sec_phone)

          setCountry(data.provider.country)
          setRegion(data.provider.region)
          setCity(data.provider.city)
          setCompanyId(data.provider.company_id)
          setVerified(data.provider.verified)
        }
        if (data.programs) {
          setProviderPrograms(data.programs)
        }
        if (data.company) {
          setCompanyName(data.company)
        }
      })
      .catch((err) => console.error(err))
    }
  }, [authState, authService, userDB.email])

  const handleReviewChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleCheckboxChange = (event) => {
    setState(event.target.checked)
    userDB['default_account']=2

    const { accessToken } = authState
      fetch(config.resourceServer + "api/users/" + userDB.email, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({"default_account":2}),
      })
      .then(response => {return response.json()})
  };

  const location = (city, region, country) => {
    return([city, region, country].filter(val => val !== '').join(', '))
  } 

  const saveChanges = () => {
    const { accessToken } = authState
    fetch(config.resourceServer + 'api/providers/' + userDB.email, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({display_name:displayName, pro_email:userTypeEmail, phone:phone, sec_first_name:displayName2, sec_email:userTypeEmail2, sec_phone:phone2, country:country, region:region, city:city}),
    })
    .then(response => {
      if (!response.ok) {
        return Promise.reject();
      }
      return response.json();
    })
    .catch((error) => console.error('Error:', error))
  }

  const program = (name, img, city, region, country, mission, id, i) =>
    <div>
      <Grid container direction="row">
        <Image style={{width:'20%', height:100}} source={img ? config.resourceServer + 'api/images/' + img : defaultImg} alt="Program"/>
        <Grid style={{marginLeft:'10px'}}>
          <Typography color='primary' inline variant="h4" align="left" component={Link} to={"/program/" + id}>
            <Box fontWeight="fontWeightBold">{name}</Box>
          </Typography>
          <Typography color='secondary' inline variant="body1" align="left">
            <Box fontWeight="fontWeightBold">{location(city, region, country)}</Box>
          </Typography>
          <Typography color='primary' inline variant="body1" align="left"  style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "800px",
            display: "block",
            overflow: "hidden",
          }}>
            {mission}
          </Typography>
        </Grid>
      </Grid>
      {i !== (providerPrograms.length - 1) &&
      <Divider variant="middle" style={{marginTop:'10px', marginBottom:'10px'}} />}
    </div>


  return (
    <div>
      <div>
        {completeRegistrationAlert}
      </div>
      <FormControlLabel
        control={
          <Checkbox
            disabled={state}
            checked={state}
            onChange={e=>handleCheckboxChange(e)}
            name="checkedB"
            color="primary"
            style={{marginTop:'-0.25em'}}
          />
        }
        label="Make Default Profile"
      />
      <div>
      <Typography color='primary' variant="h6">
        {"Provider for " + companyName}
      </Typography>
      </div>
      <AddPhoto />
      <div>
      <AppBar position="static" color="default" className={classes.topMargin}>
        <Tabs
            value={value}
            onChange={handleReviewChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
          <Tab label="Contact Information" icon={<PhoneIcon />} className={classes.disableBlueOutline}/>
          <Tab label="Secondary Contact" icon={<ContactPhoneIcon />} className={classes.disableBlueOutline} />
          <Tab label="Location Information" icon={<LocationOnIcon />} className={classes.disableBlueOutline} />
          <Tab label="Programs" icon={<EventIcon/>} className={classes.disableBlueOutline} />
        </Tabs>
      </AppBar>
      <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
      >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <ContactInfo userType="Provider" setDisplayName={setDisplayName} displayName={displayName} email={email} setUserTypeEmail={setUserTypeEmail} userTypeEmail={userTypeEmail} setPhone={setPhone} phone={phone}/>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <ContactInfo userType="Provider" setDisplayName={setDisplayName2} displayName={displayName2} email={email} setUserTypeEmail={setUserTypeEmail2} userTypeEmail={userTypeEmail2} setPhone={setPhone2} phone={phone2}/>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <LocationInfo setCountry={setCountry} country={country} setRegion={setRegion} region={region} setCity={setCity} city={city}/>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            { function() {
              let programList = []
              if (providerPrograms !== []) {
                for (let i=0; i < providerPrograms.length; i++) {
                  programList.push(program(providerPrograms[i].name, providerPrograms[i].main_picture,
                   providerPrograms[i].city, providerPrograms[i].state, providerPrograms[i].country, providerPrograms[i].mission, providerPrograms[i].id, i))
                }
              }
              return programList
            }() }
          </TabPanel>
      </SwipeableViews>
      </div>
      { value !== 3 &&
      <div className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={saveChanges}
        >
          Save
        </Button>
      </div>
    }
    { value === 3 && verified &&
      <div className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={"newProgram/"+companyId}
          width="20em"
          className={classes.button}
        >
          Add Program
        </Button>
      </div>
    }
    </div>
  )
}

export default ProviderProfile
