import React, {useEffect, useContext, useState} from 'react';
import { useOktaAuth } from '@okta/okta-react'
import Typography from '@material-ui/core/Typography';
import Like from '../menuComponents/Like'
import Followee from '../menuComponents/Followee'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip';
import config from '../../utils/config.js'
import { Grid } from '@material-ui/core';

const Search = () => {
  const { authState } = useOktaAuth()
  const [hits, setHits] = useState([])
  const [searchType, setSearchType] = useState(0)
  const { accessToken } = authState
  const pathnameArray = window.location.pathname.split("/")
  const searchKeywords = pathnameArray.pop()
  const [educatorsButton, setEducatorsButton] = useState(0);
  const [programsButton, setProgramsButton] = useState(0);
  const [educatorsButtonColor, setEducatorsButtonColor] = useState('default');
  const [programsButtonColor, setProgramsButtonColor] = useState('default');

  const programReturn = (item) => {
    return (<Like item={ {'program':      {
      id:item._id,
      name:item._source.name,
      city:item._source.city,
      parent_review:item._source.parent_review,
      educator_review:item._source.educator_review,
      main_picture:null
    }, 'company':      {
      name:item._source.company.name
    }} }
    />
    )
  }

  const followerReturn = (item) => {
    return (<Followee followee={
      {
      id: item._id,
      picture:null,
      main_role:item._source.main_role,
      display_name:item._source.display_name
      }
    }
    />
    )
  }

  useEffect(() => {
    document.title = 'Malleable Minds • Search '

    fetch(config.resourceServer + "api/es", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({search:searchKeywords, search_type:searchType}),
    })
    .then(response => {
      if (!response.ok) {
        return Promise.reject();
      }
      return response.json();
    })
    .then(json => {
      setHits(json)
    })

  }, [window.location.pathname, searchType] )

  return (
    <div>
    <Grid style={{marginBottom:10}} container>
      <Typography style={{marginRight:'10px'}} variant='h4' color='secondary'>Results for</Typography>
      <Typography variant='h4' color='secondary'>
        <Box inline fontStyle='italic'>{decodeURIComponent(searchKeywords)}</Box>
      </Typography>
    </Grid>
    <div style={{display:'flex', marginBottom:10}}>
    <Typography color='secondary' style={{paddingTop:3}}>Filter by:</Typography>
    <Chip
      label='Educators'
      color={educatorsButtonColor}
      style={{marginLeft:5, marginRight:10}}
      onClick={()=>{
        if (educatorsButton === 1) {
          setEducatorsButtonColor('default')
          setSearchType(0)
          setEducatorsButton(0)
        }

        else {
          setEducatorsButtonColor('primary')
          setProgramsButtonColor('default')
          setSearchType(1)
          setEducatorsButton(1)
          setProgramsButton(0)
        }
      }}
    />
    <Chip
      label='Programs'
      color={programsButtonColor}
      onClick={()=>{
        if (programsButton === 1) {
          setProgramsButtonColor('default')
          setSearchType(0)
          setProgramsButton(0)
        }

        else {
          setProgramsButtonColor('primary')
          setEducatorsButtonColor('default')
          setSearchType(2)
          setProgramsButton(1)
          setEducatorsButton(0)
        }
      }}
    />

    </div>
    {!hits.length &&
      <Typography align='center' color='secondary' inline variant="h4" fontWeight="bold">
        No search results found for query.
      </Typography>
    }
    {hits &&
     hits.map((item) => (
      item._index === "program" ? programReturn(item) : followerReturn(item)
    ))
    }
    </div>
  )
}

export default Search
