// https://material-ui.com/components/text-fields/
// add error text fields on save
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useState, useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ContactInfo from '../formComponents/ContactInfo'
import LocationInfo from '../formComponents/LocationInfo'
import Rating from '@material-ui/lab/Rating';
import PhoneIcon from '@material-ui/icons/Phone'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import RateReviewIcon from '@material-ui/icons/RateReview'
import ExperienceInfo from '../formComponents/ExperienceInfo'
import { UserContext } from '../global/UserContext'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AddPhoto from '../formComponents/AddPhoto'
import SubjectInfo from '../formComponents/SubjectInfo'
import config from '../../utils/config.js'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    marginBottom: '20px',
    justifyContent: 'center',
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  editBox: {
    position: 'absolute',
    marginTop: theme.spacing(22),
    marginLeft: theme.spacing(18),
  },
  topMargin: {
    marginTop: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(5),
    width: '20ch',
  },
  disableBlueOutline: {
    "&:focus-within": {
      outline: "none"
    },
  },
  reviewSpacing: {
    marginRight: "10px"
  },
  reviewBottom: {
    marginBottom: "15px"
  },
  reviewRating: {
    paddingTop: "3px",
    paddingLeft: "10px"
  },
  topElementPadding: {
    paddingTop: "10px",
    paddingLeft: "10px"
  },
  bottomElementPadding: {
    paddingBottom: "10px",
    paddingTop: "3px",
    paddingLeft: "10px"
  }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

const EducatorProfile = () => {
  const { userDB } = useContext(UserContext)
  const { authState, authService } = useOktaAuth()
  const classes = useStyles()
  const theme = useTheme();

  // stepper information
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState(userDB.default_account === 1)

  // educator specific contact fields
  const [ displayName, setDisplayName] = useState(userDB.first_name)
  const [ email ] = useState(userDB.email)
  const [ userTypeEmail, setUserTypeEmail] = useState(userDB.email)
  const [ phone, setPhone ] = useState(null)

  // educator specific location fields
  const [ country, setCountry ] = useState(null)
  const [ region, setRegion ] = useState(null)
  const [ city, setCity ] = useState(null)

  // educator specific child fields
  const [ experienceRows, setExperienceRows ] = useState([])
  const [ experienceError, setExperienceError ] = useState(false)

  // educator checked subjects
  const [checked, setChecked] = React.useState([-1]);
  const [ subjects, setSubjects ] = useState(null)
  const [ miscSubjects, setMiscSubjects ] = useState([])

  const [reviewsArray, setReviewsArray] = React.useState([]);

  useEffect(() => {
    document.title = 'Malleable Minds • Profile'

    // get educator reviews
    if (authState.isAuthenticated) {
      authService.getUser().then(info => {
        fetch(config.resourceServer + '/api/users/' + info.email + '/reviews')
        .then(response => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          setReviewsArray(data.edu_reviews)
        })
        .catch((err) => {
          console.error(err);
        });
      })
    }

    // get all subjects
    fetch(config.resourceServer + 'api/subjects')
    .then(response => {
      if (!response.ok) {
      return Promise.reject();
      }
      return response.json();
    })
    .then((data) => {
      setSubjects(data.subjects)
    })
    .catch((err) => console.error(err))

    // get educator information
    const { accessToken } = authState
    fetch(config.resourceServer + 'api/educators/' + userDB.email, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(response => { return response.json()})
    .then((data) => {
      if (data.educator) {
        if (data.educator.display_name)setDisplayName(data.educator.display_name)
        if (data.educator.ed_email)setUserTypeEmail(data.educator.ed_email)
        setPhone(data.educator.phone)
        setCountry(data.educator.country)
        setRegion(data.educator.region)
        setCity(data.educator.city)
      }
      if (data.roles.length) {
        setExperienceRows(data.roles)
      } else {
        setExperienceRows(experienceRows.concat({role:"",years:"", main:true, educator_user_email:userDB.email}))
      }

      if (data.subjects.length) {
        setChecked(checked.concat(data.subjects.map(subject => (
          subject.subject_id
        ))))
      }
      if (data.misc_subjects.length) {
        setMiscSubjects(data.misc_subjects)
      }

    })
    .catch((err) => console.error(err))
  }, [authState, authService, userDB.email])

  const handleReviewChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleCheckboxChange = (event) => {
    setState(event.target.checked)
    userDB['default_account']=1

    const { accessToken } = authState
      fetch(config.resourceServer + "api/users/" + userDB.email, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({"default_account":1}),
      })
      .then(response => {return response.json()})
  }

  const saveChanges = () => {
    const { accessToken } = authState
    if (value === 0 || value === 1) {
      fetch(config.resourceServer + 'api/educators/' + userDB.email, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({display_name:displayName, par_email:userTypeEmail, phone:phone, country:country, region:region, city:city}),
      })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
    }
    else if (value === 2) {
      let error = false

      for (let i=0; i < experienceRows.length; i++) {
        if (!experienceRows[i].role || !experienceRows[i].years) {
          error = true
          setExperienceError(true)
          break
        }
      }

      if (!error) {
        setExperienceError(false)

        fetch(config.resourceServer + "/api/roles/uppost/" + userDB.email, {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({role_list: experienceRows}),
        })
        .then(response => response.json());
      }
    }
    else {
      const checkedSubjects = checked.slice(1).map(item => {
          return {subject_id: item, educator_user_email: userDB.email}
      })
      const misc = miscSubjects.map(item => {
        return {is_misc:true, tier1:item}
      })

      /// post subjects
      fetch(config.resourceServer + "/api/subjects/uppost/" + userDB.email, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({subject_list: checkedSubjects, misc_list: misc}),
      })
      .then(response => response.json());
    }
  }

  const review = (reviewNum) =>
  <div>
      <Typography variant="h5" color="primary">
        Review for {<Typography variant="h5" color="primary" component={Link} to={"/program/" + reviewsArray[reviewNum].program_id}>{reviewsArray[reviewNum].program_name}</Typography>}
      </Typography>
      <div className={classes.reviewBottom}>
        <Box fontStyle="italic">
          <Typography variant="h8" color="secondary">
            {reviewsArray[reviewNum].review.date !== null && new Date(reviewsArray[reviewNum].review.date).toLocaleDateString("en-US", {timeZone: "Europe/London"})}
          </Typography>
        </Box>
      </div>
      <AppBar className={classes.reviewBottom} position="static">
        <Grid className={classes.topElementPadding} container>
          <Rating className={classes.topElementPadding} name="Engagement Rating" precision={0.5} value={reviewsArray[reviewNum].edu_review.appropriate} readOnly />
          <Typography className={classes.topElementPadding} inline variant="body1">Appropriate for Age</Typography>
        </Grid>
        <Grid className={classes.reviewRating} container>
          <Rating className={classes.reviewRating} name="Safe Rating" precision={0.5} value={reviewsArray[reviewNum].edu_review.knowledgeable} readOnly />
          <Typography className={classes.reviewRating} inline variant="body1">Knowledgeable Staff</Typography>
        </Grid>
        <Grid className={classes.reviewRating} container>
          <Rating className={classes.reviewRating} name="Value Rating" precision={0.5} value={reviewsArray[reviewNum].review.value} readOnly />
          <Typography className={classes.reviewRating} inline variant="body1">Value</Typography>
        </Grid>
        <Grid className={classes.bottomElementPadding} container>
          <Rating className={classes.bottomElementPadding} name="Recommendation Rating" precision={0.5} value={reviewsArray[reviewNum].review.recommend} readOnly />
          <Typography className={classes.bottomElementPadding} inline variant="body1">Recommend to Others</Typography>
        </Grid>
      </AppBar>
      <Typography className={classes.reviewBottom}>{reviewsArray[reviewNum].review.description}</Typography>
      {reviewNum !== (reviewsArray.length-1) &&
      <Divider className={classes.root} variant="middle"/>}
  </div>

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            disabled={state}
            checked={state}
            onChange={e=>handleCheckboxChange(e)}
            name="checkedB"
            color="primary"
            style={{marginTop:'-0.25em'}}
          />
        }
        label="Make Default Profile"
      />
      <AddPhoto/>
      <div>
      <AppBar position="static" color="default" className={classes.topMargin}>
        <Tabs
            value={value}
            onChange={handleReviewChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
          <Tab label="Contact Information" icon={<PhoneIcon />} className={classes.disableBlueOutline}/>
          <Tab label="Location Information" icon={<LocationOnIcon />} className={classes.disableBlueOutline} />
          <Tab label="Experience" icon={<AssignmentTurnedInIcon />} className={classes.disableBlueOutline} />
          <Tab label="Subjects" icon={<AssignmentTurnedInIcon />} className={classes.disableBlueOutline} />
          <Tab label="Reviews" icon={<RateReviewIcon />} className={classes.disableBlueOutline} />
        </Tabs>
      </AppBar>
      <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
      >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <ContactInfo userType="Educator" setDisplayName={setDisplayName} displayName={displayName} email={email} setUserTypeEmail={setUserTypeEmail} userTypeEmail={userTypeEmail} setPhone={setPhone} phone={phone}/>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <LocationInfo setCountry={setCountry} country={country} setRegion={setRegion} region={region} setCity={setCity} city={city}/>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ExperienceInfo setExperienceRows={setExperienceRows} experienceRows={experienceRows} setExperienceError={setExperienceError} experienceError={experienceError}/>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <SubjectInfo setChecked={setChecked} checked={checked} setSubjects={setSubjects} subjects={subjects} setMiscSubjects={setMiscSubjects} miscSubjects={miscSubjects}/>
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            { function() {
              let rows = []
              for (let i=0; i < reviewsArray.length; i++) {
                rows.push(review(i))
              }
              return rows
            }() }
            {!reviewsArray.length &&
            <Typography>No Reviews Yet!</Typography>}
          </TabPanel>
      </SwipeableViews>
      </div>
      { value !== 4 &&
      <div className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={saveChanges}
        >
          Save
        </Button>
      </div>
    }
    </div>
  )
}

// <Button
//   variant="contained"
//   color="primary"
//   component="span"
//   width="20em"
//   className={classes.button}
//   startIcon={<DeleteIcon />}
// >
//   Cancel
// </Button>

export default EducatorProfile
