import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {Typography} from '@material-ui/core'
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1'
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1'
import IconButton from '@material-ui/core/IconButton'
import { UserContext } from '../global/UserContext'
import FormHelperText from '@material-ui/core/FormHelperText';
import {useStyles} from '../../utils/css.js'

const ChildInfo = (props) => {
  const classes = useStyles()
  const { userDB } = useContext(UserContext)
  const [numChildren, setNumChildren] = React.useState(props.childRows.length)

  const addChild = () => {
    setNumChildren(numChildren+1)

    props.setChildRows(props.childRows.concat({name:"",grade:"", parent_user_email:userDB.email}))
  }

  const deleteChild = () => {
    setNumChildren(numChildren-1)

    props.childRows.pop()
  }

  const changeChildName = (value, childNum) => {
    props.childRows[childNum].name = value
  }

  const changeChildGrade = (value, childNum) => {
    props.childRows[childNum].grade = value
  }

  const formItem = (childNum) =>
    <div>
      <div className={classes.formRow}>
        <div className={classes.flex1}>
            <InputLabel className={classes.formField}>
              Name
            </InputLabel>
            <TextField
              className={classes.formField}
              defaultValue={props.childRows[childNum].name}
              onChange={(e)=>changeChildName(e.target.value, childNum)}
              helperText={props.childError && !props.childRows[childNum].name ? "Field cannot be empty" : ""}
              error={props.childError && !props.childRows[childNum].name}
            />
        </div>
        <div style={{ alignSelf: 'flex-end', marginLeft: 20 }}>
        <InputLabel className={classes.formField}>
          Grade
        </InputLabel>
        <Select className={classes.formField}
          defaultValue={props.childRows[childNum].grade}
          onChange={(e)=>changeChildGrade(e.target.value, childNum)}
          error={props.childError && numChildren && !props.childRows[childNum].grade}
          >
          <MenuItem value={1}>K</MenuItem>
          <MenuItem value={2}>1</MenuItem>
          <MenuItem value={3}>2</MenuItem>
          <MenuItem value={4}>3</MenuItem>
          <MenuItem value={5}>4</MenuItem>
          <MenuItem value={6}>5</MenuItem>
          <MenuItem value={7}>6</MenuItem>
          <MenuItem value={8}>7</MenuItem>
          <MenuItem value={9}>8</MenuItem>
          <MenuItem value={10}>9</MenuItem>
          <MenuItem value={11}>10</MenuItem>
          <MenuItem value={12}>11</MenuItem>
          <MenuItem value={13}>12</MenuItem>
        </Select>
        <FormHelperText className={classes.formField} style={props.childError && !props.childRows[childNum].grade ? {color:'red'}: {}}>
            {props.childError && !props.childRows[childNum].grade ? "Field cannot be empty" : ""}
        </FormHelperText>
        </div>
      </div>
    </div>

  return (
    <div>
      <Typography align="center" variant="h4" component="h4" color='primary'>
        Children
      </Typography>

      <div className={classes.flexCenter + ' ' + classes.formError}>
        <form noValidate autoComplete="off">
          { function() {
            let rows = []
            for (let i=0; i < numChildren; i++) {
              rows.push(formItem(i))
            }
            return rows
          }() }
          <div>
            <IconButton
              onClick={() => addChild()}
              color="primary"
            >
              <ExposurePlus1Icon />
            </IconButton>
            { numChildren > 1 &&
              <IconButton
                onClick={() => deleteChild()}
                color="primary"
              >
                <ExposureNeg1Icon />
              </IconButton>
            }

          </div>
        </form>
      </div>
    </div>

  )
}

export default ChildInfo
