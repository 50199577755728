import Notification from './Notification'
import React, {useEffect, useContext, useState} from 'react';
import { UserContext } from '../global/UserContext'
import { useOktaAuth } from '@okta/okta-react'
import Typography from '@material-ui/core/Typography';

import config from '../../utils/config.js'

const Notifications = () => {
    const { userDB } = useContext(UserContext)
    const { authState } = useOktaAuth()
    const [ educatorNotifications, setEducatorNotifications ] = useState([])
    const [ programNotifications, setProgramNotifications ] = useState([])

    useEffect(() => {
      document.title = 'Malleable Minds • Notifications'

      if (userDB.current_account === 0) {
        const { accessToken } = authState
        fetch(config.resourceServer + "/api/notifications/parent/" + userDB.email, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(response => response.json())
        .then((data) => {
          setProgramNotifications(data.program_messages)
          setEducatorNotifications(data.edu_messages)} )
        .catch(err => console.error(err) );
      }
      else if (userDB.current_account === 1) {
        const { accessToken } = authState
        fetch(config.resourceServer + "/api/notifications/educator/" + userDB.email, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(response => response.json())
        .then((data) => {
          setProgramNotifications(data.program_messages)
          setEducatorNotifications(data.edu_messages)} )
        .catch(err => console.error(err) );
      }
    }, [userDB, authState] )

    return (
        <div>
            { !educatorNotifications.length && !programNotifications.length &&
              <Typography align='center' color='secondary' inline variant="h4" fontWeight="bold">
                Once you receive a notification, you'll see it here.
              </Typography>
            }
            {educatorNotifications.length > 0 && educatorNotifications.slice().reverse().map((notification) => (
                <Notification notification={notification} type='educator'/>
            ))}
            {programNotifications.length > 0 && programNotifications.slice().reverse().map((notification) => (
                <Notification notification={notification} type='program'/>
            ))}
        </div>
    )
}

export default Notifications
