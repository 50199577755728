import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import ContactInfo from '../formComponents/ContactInfo'
import LocationInfo from '../formComponents/LocationInfo'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import IconButton from '@material-ui/core/IconButton'
import AddPhoto from '../formComponents/AddPhoto'
import { UserContext } from '../global/UserContext'
import config from '../../utils/config.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  backButton: {
    marginRight: theme.spacing(1),
    justifyContent: 'center'
  },
  instructions: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
  feet: {
    marginTop: theme.spacing(5),
  },
  disableBlueHover: {
    "&:hover": {
      color: theme.palette.primary.light,
    },
  }
}));

function getSteps() {
  return ['Upload a Profile Photo', 'Add a Main Contact', 'Add Secondary Contact', 'Enter Location'];
}

const ProviderProgressStepper = () => {
  const { userDB } = useContext(UserContext)
  const { authState, authService } = useOktaAuth()
  const classes = useStyles()

  // stepper information
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  // primary provider specific contact fields
  const [ displayName, setDisplayName] = useState(userDB.first_name)
  const [ email ] = useState(userDB.email)
  const [ userTypeEmail, setUserTypeEmail] = useState(userDB.email)
  const [ phone, setPhone ] = useState(null)

  // secondary provider specific contact fields
  const [ displayName2, setDisplayName2] = useState(null)
  const [ userTypeEmail2, setUserTypeEmail2] = useState(null)
  const [ phone2, setPhone2 ] = useState(null)

  // provider specific location fields
  const [ country, setCountry ] = useState(null)
  const [ region, setRegion ] = useState(null)
  const [ city, setCity ] = useState(null)

  useEffect(() => {
    document.title = 'Malleable Minds • Registration'
    
    if (authState.isAuthenticated) {
      const { accessToken } = authState
      fetch(config.resourceServer + 'api/providers/' + userDB.email, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => {
        if (!response.ok) {
        return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        if (data.provider) {
          if (data.provider.display_name)setDisplayName(data.provider.display_name)
          if (data.provider.pro_email)setUserTypeEmail(data.provider.pro_email)
          setPhone(data.provider.phone)

          setDisplayName2(data.provider.sec_first_name)
          setUserTypeEmail2(data.provider.sec_email)
          setPhone2(data.provider.sec_phone)

          setCountry(data.provider.country)
          setRegion(data.provider.region)
          setCity(data.provider.city)
        }
      })
      .catch((err) => console.error(err))
    }
  }, [authState, authService, userDB.email])

  function getStepPage(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <AddPhoto />;
      case 1:
        return <ContactInfo userType="Provider" setDisplayName={setDisplayName} displayName={displayName} email={email} setUserTypeEmail={setUserTypeEmail} userTypeEmail={userTypeEmail} setPhone={setPhone} phone={phone}/>;
      case 2:
        return <ContactInfo userType="Provider" setDisplayName={setDisplayName2} displayName={displayName2} email={email} setUserTypeEmail={setUserTypeEmail2} userTypeEmail={userTypeEmail2} setPhone={setPhone2} phone={phone2}/>;
      case 3:
        return <LocationInfo setCountry={setCountry} country={country} setRegion={setRegion} region={region} setCity={setCity} city={city}/>;
      default:
        return '';
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return 'Provide a picture for your profile';
      case 1:
        return 'Tell us about yourself';
      case 2:
        return 'Add a secondary contact';
      case 3:
        return "What area are you in?";
      default:
        return '';
    }
  }

  const handleNext = () => {
    const { accessToken } = authState
    fetch(config.resourceServer + 'api/providers/' + userDB.email, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({display_name:displayName, pro_email:userTypeEmail, phone:phone, sec_first_name:displayName2, sec_email:userTypeEmail2, sec_phone:phone2, country:country, region:region, city:city}),
    })
    .then(response => {
      if (!response.ok) {
        return Promise.reject();
      }
      return response.json();
    })
    .catch((error) => console.error('Error:', error))

    if (activeStep < 3) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    else window.location.href = config.hostServer
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className={classes.root}>
        <Typography color="primary" variant="h4" component="h4" className={classes.instructions}>
          {getStepContent(activeStep)}
        </Typography>
      </div>
      {getStepPage(activeStep)}
      <div className={classes.root}>
        <div className={classes.feet}>

          <IconButton className={classes.disableBlueHover}
              color="primary"
              aria-label="upload picture"
              component="span"
              disabled={activeStep === 0}
              onClick={handleBack}>
            <ArrowBackIosIcon />
          </IconButton>

          <IconButton className={classes.disableBlueHover}
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleNext}>
            <ArrowForwardIosIcon />
          </IconButton>
          </div>
      </div>
    </div>
  );
}

export default ProviderProgressStepper
