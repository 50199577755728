import React, { useState} from 'react';
import ProgramForm from './ProgramForm'


const NewProgram = () => {
  const [image, setImage] = useState('')
  const [program, setProgram] = useState('')
  const [company, setCompany] = useState({id:window.location.pathname.split("/").pop()})


  return (
    <ProgramForm
      image={image} setImage={setImage}
      company={company} setCompany={setCompany}
      program={program} setProgram={setProgram}
    />
  );
}

export default NewProgram
