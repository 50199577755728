import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AvatarEditor from 'react-avatar-editor'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Image } from 'react-native';
import {useStyles} from '../../utils/css.js'

import defaultImg from '../../images/base/default_img_icon.jpg'

const AddHeaderPhoto = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [editor, setEditor] = useState(null)
  const [displayImg, setDisplayImg] = useState(props.image)
  const [prevImg, setPrevImg] = useState("")

  const handleNewImage = e => {
    const fr = new FileReader()
    fr.addEventListener("load", function(){
      if (!prevImg){
        setPrevImg(displayImg)
      }
      setDisplayImg(fr.result)
    }, false)
    fr.readAsDataURL(e.target.files[0])
  }

  const saveImage = () => {
    const canvas = editor.getImage().toDataURL()
    props.setImage(canvas)
    setPrevImg("")

    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setDisplayImg(prevImg)
    setPrevImg("")
    setOpen(false)
  };

  const setEditorRef = editor => {
    if (editor) setEditor(editor)
  }

  return (
      <div className={classes.flexCenter}>
        <Image style={{width:'100%', height:300}} source={displayImg ? displayImg : defaultImg} alt="Program"/>
        <div className={classes.addAHeaderPhotoIcon}>
          <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen}>
            <AddAPhotoIcon />
          </IconButton>
        </div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth = {'lg'}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" color="primary">Upload a Profile Picture</Typography>
          </DialogTitle>
          <DialogContent>
            <AvatarEditor
              ref={setEditorRef}
              image={displayImg}
              width={1110}
              height={600}
              border={0}
              color={[200, 200, 200, 0.6]} // RGBA
              scale={1}
              rotate={0}
              crossOrigin="anonymous"
            />
            <div>
            <input name="newImage" type="file" onChange={handleNewImage} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={saveImage} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  )
}

export default AddHeaderPhoto
