import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1'
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1'
import IconButton from '@material-ui/core/IconButton'
import {useStyles} from '../../utils/css.js'

export default function CheckboxList(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState([-1]);
  const [numMisc, setNumMisc] = React.useState(props.miscSubjects.length)

  const handleClick = (value) => () => {
    const currentIndex = open.indexOf(value);
    const newOpen = [...open];

    if (currentIndex === -1) {
      newOpen.push(value);
    } else {
      newOpen.splice(currentIndex, 1);
    }

    setOpen(newOpen);
  };

  const handleToggle = (value) => () => {
    const currentIndex = props.checked.indexOf(value);
    const newChecked = [...props.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    props.setChecked(newChecked);
  };

  const addMisc = () => {
    setNumMisc(numMisc+1)

    props.setMiscSubjects(props.miscSubjects.concat(""))
  }

  const deleteMisc = () => {
    setNumMisc(numMisc-1)

    props.miscSubjects.pop()
  }

  const changeMisc = (value, miscNum) => {
    props.miscSubjects[miscNum] = value
    props.setMiscSubjects(props.miscSubjects)
  }

  const listItem = (value) => {
    const labelId = `checkbox-list-label-${value.name}`;
    return (
      <ListItem key={value.id} role={undefined} dense button onClick={handleToggle(value.id)}>
        <ListItemIcon>
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            edge="start"
            checked={props.checked.indexOf(value.id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={value.name} />
      </ListItem>
    )
  }

  const listExpandItem = (value) => {
    const labelId = `checkbox-list-label-${value}`;

    return (
      <div>
      <ListItem key={value} role={undefined} dense button onClick={handleToggle(value[0].id)}>
        <ListItemIcon>
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            edge="start"
            checked={props.checked.indexOf(value[0].id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={value[0].name} />
        <ListItemSecondaryAction role={undefined} dense button onClick={handleClick(value[0].id)}>
          {(open.indexOf(value[0].id) !== -1) ? <ExpandLess /> : <ExpandMore />}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open.indexOf(value[0].id) !== -1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{marginLeft:45}}>
          {Array.isArray(value[1][0]) && value[1][0].length
            ? value[1].map(childValue => childValue[1].length?listExpandItem(childValue):listItem(childValue[0]))
            : value[1].length ? value[1].map(childValue => listItem(childValue)) : ""
          }
        </List>
      </Collapse>
      </div>
    )
  }

  const misc = (miscNum, value) => {

    return (
      <ListItem >
        <TextField
          defaultValue={value}
          className={classes.width35ch}
          id={"write-in" + value}
          onChange={(e)=>changeMisc(e.target.value, miscNum)}
        />
      </ListItem>
    )
  }

  return (
    <List>
      {props.subjects.map((value) => {
        return (
          <div>
            {value[1].length?listExpandItem(value):listItem(value[0])}
          </div>
        );
      })}
      {props.miscSubjects.map((value, index) => {
        return (
          <div>
            {misc(index, value)}
          </div>
        )
      })}
      <IconButton
        onClick={() => addMisc()}
        color="primary"
      >
        <ExposurePlus1Icon />
      </IconButton>
      { numMisc > 0 &&
        <IconButton
          onClick={() => deleteMisc()}
          color="primary"
        >
          <ExposureNeg1Icon />
        </IconButton>
      }


    </List>
  );
}
