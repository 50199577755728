import React, {useEffect, useContext, useState} from 'react';
import Like from './Like'
import { UserContext } from '../global/UserContext'
import { useOktaAuth } from '@okta/okta-react'
import Typography from '@material-ui/core/Typography';

import config from '../../utils/config.js'

const Likes = () => {
  const { userDB } = useContext(UserContext)
  const { authState } = useOktaAuth()
  const [ likes, setLikes ] = useState([])

  useEffect(() => {
    document.title = 'Malleable Minds • Likes'
  
    if (userDB.current_account === 0) {
      const { accessToken } = authState
      fetch(config.resourceServer + "/api/users/parent/" + userDB.email +"/likes", {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then(response => response.json())
      .then((data) => setLikes(data.likes) )
      .catch(err => console.error(err) );
    }
    else if (userDB.current_account === 1) {
      const { accessToken } = authState
      fetch(config.resourceServer + "/api/users/educator/" + userDB.email +"/likes", {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then(response => response.json())
      .then((data) => setLikes(data.likes) )
      .catch(err => console.error(err) );
    }
  }, [userDB, authState] )

  return (
    <div>
    { !likes.length &&
      <Typography align='center' color='secondary' inline variant="h4" fontWeight="bold">
        Once you like a program, you'll see it here.
      </Typography>
    }
    { likes &&
      likes.map((item) => (
      <Like item={item}/>
      ))
    }
    </div>
  )
}

export default Likes
