import React, {useState, useEffect} from 'react';

import defaultImg from '../images/base/default_img_icon.jpg'
import { Image } from "react-native";

import config from '../utils/config.js'

const ProgramImage = (props) =>  {
  const [ image, setImage ] = useState(null)

  useEffect(() => {

      fetch(config.resourceServer + "/api/programs/image/" + props.id)
      .then(response => response.json())
      .then((data) => {
        setImage(data.programImage)
      })
      .catch(err => console.error(err))


  }, [props.id])

  return (
      <Image
          style={{
            resizeMode: "cover",
            height: 200,
            width: 300
          }}
          source={image ? config.resourceServer + 'api/images/' + image : defaultImg}
        />

  );
}

export default ProgramImage
