import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../global/UserContext'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import config from '../../utils/config'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton'
import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(10),
    color: theme.palette.primary.light,
  },
  dropdown: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: theme.spacing(5),
  },
  rootBottom: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: theme.spacing(20)
  },
  card: {
    width: 200,
    height: 200,
    marginRight: 20,
    borderColor: theme.palette.primary.light,
  },
  cardAction: {
  "&:focus-within": {
    outline: "none"
    },
  }
}));

const ChooseUserType = () => {
  const classes = useStyles()
  const [parent, setParent] = useState(false)
  const [educator, setEducator] = useState(false)
  const [provider, setProvider] = useState(false)
  const [parentLearnMore, setParentLearnMore] = useState(false)
  const [educatorLearnMore, setEducatorLearnMore] = useState(false)
  const [providerLearnMore, setProviderLearnMore] = useState(false)
  const [companyRows, setCompanyRows] = useState([])
  const [companyId, setCompanyId] = useState(null)
  const { authState, authService } = useOktaAuth()
  const [userInfo, setUserInfo] = useState(null)
  const { userDB } = useContext(UserContext)
  const [addNew, setAddNew] = useState(false);
  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')

  const handleCardClick = (card) => {
    if (parent) setParent(false)
    else if (educator) setEducator(false)
    else setProvider(false)

    switch(card){
      case 'Parent': setParent(true); break
      case 'Educator': setEducator(true); break
      case 'Provider': setProvider(true); break
      default: break
    }
  }

  const handleCompanyChange = (e) => {
    setCompanyId(e.target.value)
  }

  useEffect(() => {
    document.title = 'Malleable Minds • Registration'

    if (!authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      authService.getUser().then(info => {
        setUserInfo(info);
      })
      if (!companyRows.length) {
        fetch(config.resourceServer + "/api/company/noprovider")
          .then(response => response.json())
          .then((data) =>{
              if (data.companies.length) {
                  setCompanyRows(data.companies.map(company =>
                    <MenuItem value={company.id}>{company.name}</MenuItem>
                  ))
              }
            })
          .catch(err => console.error(err))
      }
    }
  }, [authState, authService, companyRows.length])

  const handleRegistration = () => {
      if (parent || educator || (provider && companyId) || (provider && addNew)) {
        // okta email and information
        const email = userInfo ? userInfo.email : null
        const firstName = userInfo && userInfo.name.slice(userInfo.name, userInfo.name.lastIndexOf(' '))
        const lastName = userInfo && userInfo.name.slice(userInfo.name.lastIndexOf(' ')+1)

        // box chosen
        const userType = parent ? 0 : educator ? 1 : 2
        window.sessionStorage.setItem('current_account', userType)

        // put or post based on whether user already has an acct
        const fetchType = userDB.email ? 'PUT' : 'POST'
        const fetchBody = userDB.email
          ? {current_account:userType}
          : {email:email, first_name:firstName, last_name:lastName, default_account:userType, current_account:userType}

        if (userType === 0) fetchBody.is_parent=true
        if (userType === 1) fetchBody.is_educator=true
        if (userType === 2) fetchBody.is_provider=true

        const controllerApi = userDB.email ? '/api/users/' + userDB.email : 'api/users'

        const { accessToken } = authState

        // post/put the user account
        fetch(config.resourceServer + controllerApi, {
          method: fetchType,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(fetchBody),
        })
        .then(response => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then(() => {
          // if provider adding new company, post company & program
          if (userType === 2 && addNew)
          {
            fetch(config.resourceServer + "api/company/program", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({ name:companyName, address:companyAddress }),
            })
            .then(response => {
              if (!response.ok) {
                return Promise.reject();
              }
              return response.json();
            })
            .then(json => {
              fetch(config.resourceServer + "api/providers", {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({user_email:email, company_id:json.company_id}),
              })
              .then(response => {
                if (!response.ok) {
                  return Promise.reject();
                }
                return response.json();
              })
              .then(email => window.location.href = config.hostServer + "registerInfo")
              .catch((error) => console.error('Error:', error))
            })
            .catch(err => console.error(err))
          }

          else {
            const userTypeControllerApi = userType === 0 ? "parents" : userType === 1 ? "educators" : "providers"

            // PROVIDER ADD DISPLAY NAME ONCE IN DB
            const userTypeFetchBody = userType === 2
              ? {user_email:email, company_id:companyId}
              : {user_email:email, display_name:firstName}

            // post the parent/educator/or provider account
            fetch(config.resourceServer + "api/" + userTypeControllerApi, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(userTypeFetchBody),
            })
            .then(response => {
              if (!response.ok) {
                return Promise.reject();
              }
              return response.json();
            })
            .then(email => window.location.href = config.hostServer + "registerInfo")
            .catch((error) => console.error('Error:', error))

          }
        })
        .catch((error) => {
          console.error('Error:', error);
        })
    }
  }


  return (
    <div>
      <div className={classes.root}>
      {!userDB.is_parent &&
      <Card className={classes.card} variant={parent ? "outlined" : "contained"} style ={parentLearnMore ? {height:300} : {}}>
      <CardActionArea onClick={() => handleCardClick("Parent")} className={classes.cardAction} focusHighlight={false}>
        <CardContent>
          <Typography variant="h5" component="h2">
            parent
          </Typography>
          <Typography variant="body2" component="p">
            user searching for educational programs for 1+ children in K-12
          </Typography>
        </CardContent>
        </CardActionArea>

        <CardActions>
          <Button onClick={() => setParentLearnMore(!parentLearnMore)} size="small">Learn More</Button>
        </CardActions>
        {parentLearnMore &&
          <CardContent>
            <Typography variant="body2" component="p">
              Parents can like and review programs, follow educators, and receive notifications.
            </Typography>
          </CardContent>
        }
      </Card>
      }

      {!userDB.is_educator &&
      <Card className={classes.card} variant={educator ? "outlined" : "contained"} style ={educatorLearnMore ? {height:300} : {}}>
      <CardActionArea onClick={() => handleCardClick("Educator")} className={classes.cardAction}>
        <CardContent>
          <Typography variant="h5" component="h2">
            educator
          </Typography>
          <Typography variant="body2" component="p">
            user with experience teaching children in K-12
          </Typography>
          <br/>
        </CardContent>
        </CardActionArea>
        <CardActions>
          <Button onClick={() => setEducatorLearnMore(!educatorLearnMore)} size="small">Learn More</Button>
        </CardActions>
        {educatorLearnMore &&
          <CardContent>
            <Typography variant="body2" component="p">
              Educators can like and review programs, follow other educators, and receive notifications.
            </Typography>
          </CardContent>
        }
      </Card>
      }

      {!userDB.is_provider &&
      <Card className={classes.card} variant={provider ? "outlined" : "contained"} style ={providerLearnMore ? {height:300} : {}}>
      <CardActionArea onClick={() => handleCardClick("Provider")} className={classes.cardAction}>
        <CardContent>
          <Typography variant="h5" component="h2">
            provider
          </Typography>
          <Typography variant="body2" component="p">
            user requesting management account for educational program
          </Typography>
        </CardContent>
        </CardActionArea>

        <CardActions>
          <Button onClick={() => setProviderLearnMore(!providerLearnMore)} size="small">Learn More</Button>
        </CardActions>
        {providerLearnMore &&
          <CardContent>
            <Typography variant="body2" component="p">
              Providers can create and manage programs for one company.
            </Typography>
          </CardContent>
        }
      </Card>
      }

      </div>

      { provider && !addNew &&
        function() {
          return (
            <div>
              <div className={classes.dropdown}>
                <InputLabel>
                  Choose the company you're managing from existing companies.
                </InputLabel>
              </div>
              <div className={classes.dropdown}>
                <Select style={{minWidth: 300}} onChange={handleCompanyChange}>
                  {companyRows.map((row)=>{
                    return row
                  })}
                </Select>
              </div>
              <div className={classes.dropdown}>
                <InputLabel style={{marginTop:12}}>
                  Don't see your company? Create company and program now..
                </InputLabel>
                <IconButton color="primary" aria-label="create Program" component="span" onClick={()=>setAddNew(!addNew)}>
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          )
      }() }

      { provider && addNew &&
        <div>
          <div className={classes.dropdown}>
            <InputLabel style={{marginTop:12}}>
              View existing companies.
            </InputLabel>
            <IconButton color="primary" aria-label="create Program" component="span" onClick={()=>setAddNew(!addNew)}>
              <HistoryIcon />
            </IconButton>
          </div>
          <div className={classes.dropdown}>
            <TextField style={{width:'400px'}} variant='outlined' label='Company Name' onChange={event => setCompanyName(event.target.value)} defaultValue={companyName} />
          </div>
          <div className={classes.dropdown}>
            <TextField style={{width:'400px'}} variant='outlined' label='Company Address' onChange={event => setCompanyAddress(event.target.value)} defaultValue={companyAddress} />
          </div>
        </div>
      }

      <div className={classes.rootBottom}>
        <Button variant="contained" color="primary" component="span" size="small" onClick={handleRegistration}>
          Register
        </Button>
      </div>
    </div>
  );
}

export default ChooseUserType
