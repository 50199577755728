import React, { useEffect, useContext, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import config from '../utils/config';
import { UserContext } from './global/UserContext'
import SuggestedEdits from './SuggestedEdits'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AddHeaderPhoto from './formComponents/AddHeaderPhoto'
import FormHelperText from '@material-ui/core/FormHelperText';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      marginBottom: "20px",
      flexGrow: 1
    },
    descriptionSpacing: {
      marginBottom: "5px"
    },
    dropdownField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      height: '3ch',
      width: '40ch',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: '2px solid rgba(0, 0, 0, 0.4)',
      background: "rgba(0, 0, 0, 0.001)",
      fontFamily: 'Calibri (Body)',
      "&:focus-within": {
        outline: "none",
        background: "rgba(0, 0, 0, 0.05)"
      },
      color: 'black',
      marginBottom:'10px'
    },
    textFieldHeader: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40ch',
    },
    rootBottom: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      marginBottom: theme.spacing(20)
    },
  }))

const ProgramForm = (props) => {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false);
    const { authState, authService } = useOktaAuth();
    const { userDB } = useContext(UserContext)
    const [program] = useState(props.program);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isEditing, setIsEditing] = useState(props.isEditing)
    const [openSuggestedEdits, setSuggestedEditsOpen] = useState(false)
    const [programType, setProgramType] = useState('');
    const [scholarshipAvailable, setScholarshipAvailable] = useState('');
    const [remote, setRemote] = useState('');
    const [country, setCountry] = useState('')
    const [region, setRegion] = useState('')
    const [name, setName] = useState(props.program.name !== undefined ? props.program.name : '')
    const [city, setCity] = useState('')
    const [startCost, setStartCost] = useState('')
    const [endCost, setEndCost] = useState('')
    const [startGrade, setStartGrade] = useState('')
    const [endGrade, setEndGrade] = useState('')
    const [established, setEstablished] = useState('')
    const [mission, setMission] = useState('')
    const [description, setDescription] = useState('')
    const [website, setWebsite] = useState('')
    const [image, setImage] = useState(props.image)
    const [editComplete] = useState(props.editComplete)
    const [gradeLevelError, setGradeLevelError] = useState(false)
    const [costStartTypeError, setCostStartTypeError] = useState(false)
    const [costEndTypeError, setCostEndTypeError] = useState(false)
    const [costRangeError, setCostRangeError] = useState(false)
    const [durationError, setDurationError] = useState(false)
    const [startDateError, setStartDateError] = useState(false)
    const [endDateError, setEndDateError] = useState(false)
    const [establishmentDateInvalidError, setEstablishmentDateInvalidError] = useState(false)
    const [establishmentDateRangeError, setEstablishmentDateRangeError] = useState(false)
    const [nameError, setNameError] = useState(false)

    const handleDetailsChange = panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handleClickSuggestedEditsOpen = () => {
      setSuggestedEditsOpen(true);
    };

    const handleCloseSuggestedEdits = () => {
      setSuggestedEditsOpen(false);
    };

    const handleName = (name) => {
      if (name.trim() === '') {
        setNameError(true)
      }

      else {
        setNameError(false)
      }

      setName(name)
    }

    const handleStartDateChange = (date) => {
      if (!isNaN(date)) {
        setStartDateError(false)

        if (new Date(date).getTime() > new Date(endDate).getTime()) {
          setDurationError(true)
        }

        else {
          setDurationError(false)
        }
      }

      else {
        setStartDateError(true)
        setDurationError(false)
      }

      setStartDate(date);
    };

    const handleEndDateChange = (date) => {
      if (!isNaN(date)) {
        setEndDateError(false)

        if (new Date(startDate).getTime() > new Date(date).getTime()) {
          setDurationError(true)
        }

        else {
          setDurationError(false)
        }
      }

      else {
        setEndDateError(true)
        setDurationError(false)
      }

      setEndDate(date);
    };

    const handleEstablishmentDateChange = (date) => {
      if (!isNaN(date)) {
        setEstablishmentDateInvalidError(false)

        if (new Date(date).getTime() > new Date().getTime()) {
          setEstablishmentDateRangeError(true)
        }

        else {
          setEstablishmentDateRangeError(false)
        }
      }

      else {
        setEstablishmentDateInvalidError(true)
        setEstablishmentDateRangeError(false)
      }

      setEstablished(date);
    };

    const handleStartGradeChange = (grade) => {
      if (grade && endGrade) {
        if (grade > endGrade) {
          setGradeLevelError(true)
        }

        else {
          setGradeLevelError(false)
        }
      }

      else {
        setGradeLevelError(false)
      }

      setStartGrade(grade)
    }

    const handleEndGradeChange = (grade) => {
      if (startGrade && grade) {
        if (startGrade > grade) {
          setGradeLevelError(true)
        }

        else {
          setGradeLevelError(false)
        }
      }

      else {
        setGradeLevelError(false)
      }

      setEndGrade(grade)
    }

    const handleStartCost = (cost) => {
      if (!isNaN(cost) && Number.isInteger(parseFloat(cost))) {
        setCostStartTypeError(false)

        if (Number.isInteger(parseFloat(endCost)) && parseInt(cost) >= parseInt(endCost)) {
          setCostRangeError(true)
        }

        else {
          setCostRangeError(false)
        }
      }
      else {
        if (cost === '') {
          setCostStartTypeError(false)
        }
        else {
          setCostStartTypeError(true)
        }
        setCostRangeError(false)
      }

      setStartCost(cost)
    }

    const handleEndCost = (cost) => {
      if (!isNaN(cost) && Number.isInteger(parseFloat(cost))) {
        setCostEndTypeError(false)

        if (Number.isInteger(parseFloat(startCost)) && parseInt(startCost) >= parseInt(cost)) {
          setCostRangeError(true)
        }

        else {
          setCostRangeError(false)
        }
      }

      else {
        if (cost === '') {
          setCostEndTypeError(false)
        }
        else {
          setCostEndTypeError(true)
        }
        setCostRangeError(false)
      }

      setEndCost(cost)
    }

    const handleEditingCancel = () => {
      setIsEditing(false)
      handleProgramDetails(program)
      setGradeLevelError(false)
      setCostStartTypeError(false)
      setCostEndTypeError(false)
      setCostRangeError(false)
      setDurationError(false)
      setStartDateError(false)
      setEndDateError(false)
      setEstablishmentDateInvalidError(false)
      setEstablishmentDateRangeError(false)
      setNameError(false)
      props.setIsEditing(false)
    }

    const handleProgramDetails = (data) => {
      setProgramType(data.type)
      setRemote(data.remote)
      setScholarshipAvailable(data.scholarship)
      setRegion(data.state)
      setStartDate(data.start ? new Date(data.start).toLocaleDateString("en-US", {timeZone: "Europe/London"}) : null)
      setEndDate(data.end ? new Date(data.end).toLocaleDateString("en-US", {timeZone: "Europe/London"}) : null)
      setName(data.name)
      setCity(data.city)
      setCountry(data.country)
      setStartCost(data.start_cost === -1 ? null : data.start_cost)
      setEndCost(data.end_cost === -1 ? null : data.end_cost)
      setStartGrade(data.start_grade)
      setEndGrade(data.end_grade)
      setEstablished(data.established ? new Date(data.established).toLocaleDateString("en-US", {timeZone: "Europe/London"}) : null)
      setMission(data.mission)
      setDescription(data.description)
      setWebsite(data.website)
    }

    const handleEditing = () => {
      if (name.trim() === '') {
        setNameError(true)
      }

      else{
        if (!gradeLevelError && !costStartTypeError && !costEndTypeError && !costRangeError && !durationError
          && !startDateError && !endDateError & !establishmentDateInvalidError && !establishmentDateRangeError
          && !nameError) {
          const updatedProgramDetails = {
            'company_id':props.company.id,
            'name':name,
            'city':city,
            'state':region,
            'country':country,
            'remote':remote,
            'start_cost':startCost ? startCost : -1,
            'end_cost':endCost ? endCost : -1,
            'scholarship':scholarshipAvailable,
            'type':programType,
            'start':startDate,
            'end':endDate,
            'start_grade':startGrade,
            'end_grade':endGrade,
            'established':established,
            'mission':mission,
            'description':description,
            'website':website,
            'main_picture':image,
          }

          const fetchType = props.isEditing !== undefined ? "PUT" : "POST"
          const fetchApi = props.isEditing !== undefined ? '/api/programs/' + program.id : '/api/programs'
          if (props.isEditing !== undefined) {
            updatedProgramDetails['id'] = program.id
          }

          if ((isEditing === true || props.isEditing === undefined) && authState.isAuthenticated) {
            const { accessToken } = authState
            authService.getUser().then(() => {
              fetch(config.resourceServer + fetchApi, {
                method: fetchType,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(updatedProgramDetails)
              })
              .then(response => {
                if (!response.ok) {
                return Promise.reject();
                }
                return response.json();
              })
              .then(() => {
                  setGradeLevelError(false)
                  setCostStartTypeError(false)
                  setCostEndTypeError(false)
                  setCostRangeError(false)
                  setDurationError(false)
                  setStartDateError(false)
                  setEndDateError(false)
                  setEstablishmentDateInvalidError(false)
                  setEstablishmentDateRangeError(false)
                  setNameError(false)
                  if (props.setIsEditing !== undefined) {
                    props.setIsEditing(!isEditing)
                    props.setEditComplete(!editComplete)
                  }
                  else {
                    window.location.href = config.hostServer + "profile"
                  }
              })
              .catch(err => {
                  console.error(err);
              });
            })
          }
        }
      }
    }

    useEffect(() => {
        document.title = 'Malleable Minds • Program'
        handleProgramDetails(props.program)
    }, [userDB.current_account, authService, authState, editComplete, props.program])

    return (
      <div>
        <AddHeaderPhoto setImage={setImage} image={image}/>
        <Grid container direction="row" justify="space-between" className={classes.root} alignItems="center" spacing={16}>
          <Grid container direction="column" sm={8}>
            <Grid container direction="column" sm={8}>
              <TextField style={{marginTop:'10px'}} 
                        onChange={event => handleName(event.target.value)} 
                        variant='outlined' 
                        id='name' 
                        label='Program Name' 
                        value={name} 
                        error={nameError}
                        helperText={nameError && "Program must have a name."}
              />
              <div className={classes.root}>
                <form noValidate autoComplete="off">
                <div>
                  <InputLabel className={classes.textFieldHeader} id={"country"}>Country</InputLabel>
                  <CountryDropdown
                    defaultOptionLabel=""
                    className={classes.dropdownField}
                    onChange={(val) => setCountry(val)}
                    value={country}
                    />
                </div>
                <div>
                  <InputLabel className={classes.textFieldHeader} id={"region"}>Region</InputLabel>
                  <RegionDropdown
                    defaultOptionLabel=""
                    className={classes.dropdownField}
                    country={country}
                    value={region}
                    onChange={(val) => setRegion(val)}
                  />
                </div>
                <div>
                  <TextField style={{marginTop:'10px'}} variant='outlined' id='city' label='City' onChange={event => setCity(event.target.value)} value={city} />
                </div>
                </form>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="column" sm={3} align="right">
            <Grid container justify='center'>
              {authState.isAuthenticated && props.isEditing !== undefined &&
              <Grid container justify='center'>
                <Button style={{marginRight: '10px'}} variant="contained" onClick={handleEditingCancel}>Cancel</Button>
                <Button variant="contained" onClick={handleEditing}>Save</Button>
                <Button style={{marginTop: '10px'}} variant="contained" onClick={handleClickSuggestedEditsOpen}>Suggested Edits</Button>
                <Dialog open={openSuggestedEdits} onClose={handleCloseSuggestedEdits} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title" >
                    <Typography variant="h6" color="primary">Suggested Edits for {name}</Typography>
                  </DialogTitle>
                  <DialogContent>
                    <SuggestedEdits id={program.id}/>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseSuggestedEdits} color="primary">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.root} variant="middle"/>
        <div>
            <Grid className={classes.descriptionSpacing} container justify="space-between">
              <Typography color='secondary' inline variant="body1" align="left">
                <Box fontWeight="fontWeightBold">Grade Level</Box>
              </Typography>
              <div style={{marginBottom:10}}>
                <FormControl style={{width:'109px'}} variant="outlined">
                  <InputLabel>Start Grade</InputLabel>
                  <Select
                    variant='outlined'
                    label='Start Grade'
                    id='start_grade'
                    value={startGrade}
                    error={gradeLevelError}
                    onChange={event => handleStartGradeChange(event.target.value)}
                    >
                    <MenuItem value={0}>
                      N/A
                    </MenuItem>
                    <MenuItem value={1}>K</MenuItem>
                    <MenuItem value={2}>1</MenuItem>
                    <MenuItem value={3}>2</MenuItem>
                    <MenuItem value={4}>3</MenuItem>
                    <MenuItem value={5}>4</MenuItem>
                    <MenuItem value={6}>5</MenuItem>
                    <MenuItem value={7}>6</MenuItem>
                    <MenuItem value={8}>7</MenuItem>
                    <MenuItem value={9}>8</MenuItem>
                    <MenuItem value={10}>9</MenuItem>
                    <MenuItem value={11}>10</MenuItem>
                    <MenuItem value={12}>11</MenuItem>
                    <MenuItem value={13}>12</MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{marginLeft:'5px', width:'109px'}} variant="outlined">
                  <InputLabel>End Grade</InputLabel>
                  <Select
                    label='End Grade'
                    id='end_grade'
                    value={endGrade}
                    error={gradeLevelError}
                    onChange={event => handleEndGradeChange(event.target.value)}
                    >
                    <MenuItem value={0}>
                      N/A
                    </MenuItem>
                    <MenuItem value={1}>K</MenuItem>
                    <MenuItem value={2}>1</MenuItem>
                    <MenuItem value={3}>2</MenuItem>
                    <MenuItem value={4}>3</MenuItem>
                    <MenuItem value={5}>4</MenuItem>
                    <MenuItem value={6}>5</MenuItem>
                    <MenuItem value={7}>6</MenuItem>
                    <MenuItem value={8}>7</MenuItem>
                    <MenuItem value={9}>8</MenuItem>
                    <MenuItem value={10}>9</MenuItem>
                    <MenuItem value={11}>10</MenuItem>
                    <MenuItem value={12}>11</MenuItem>
                    <MenuItem value={13}>12</MenuItem>
                  </Select>
                </FormControl>
                {gradeLevelError && <FormHelperText style={{width:223, color:'red'}}>Start grade must be less than or equal to the end grade.</FormHelperText>}
              </div>
            </Grid>
            <Grid className={classes.descriptionSpacing} container justify="space-between">
              <Typography color='secondary' inline variant="body1" align="left">
                <Box fontWeight="fontWeightBold">Program Type</Box>
              </Typography>
              <FormControl style={{marginBottom:'10px', minWidth:'223px'}} variant="outlined">
                <InputLabel>Program Type</InputLabel>
                <Select
                  id="type"
                  value={programType}
                  onChange={(event) => setProgramType(event.target.value)}
                  label="Program Type"
                >
                  <MenuItem value={'Spring'}>Spring</MenuItem>
                  <MenuItem value={'Summer'}>Summer</MenuItem>
                  <MenuItem value={'Fall'}>Fall</MenuItem>
                  <MenuItem value={'Winter'}>Winter</MenuItem>
                  <MenuItem value={'Academic Year'}>Academic Year</MenuItem>
                  <MenuItem value={'Full Year'}>Full Year</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.descriptionSpacing} container justify="space-between">
              <Typography color='secondary' inline variant="body1" align="left">
                <Box fontWeight="fontWeightBold">Cost</Box>
              </Typography>
              <div style={{marginBottom:'10px'}}>
                <TextField style={{width:'109px'}}
                          variant='outlined' id='start_cost'
                          label='Start Cost'
                          onChange={event => handleStartCost(event.target.value)}
                          value={startCost}
                          error={costStartTypeError || costRangeError}
                          helperText={costStartTypeError && "Start cost must be an integer."}
                />
                <TextField style={{marginLeft:'5px', width:'109px'}}
                          variant='outlined'
                          id='end_cost'
                          label='End Cost'
                          onChange={event => handleEndCost(event.target.value)}
                          value={endCost}
                          error={costEndTypeError || costRangeError}
                          helperText={costEndTypeError && "End cost must be an integer."}
                />
                {!costStartTypeError && !costEndTypeError && costRangeError && <FormHelperText style={{width:223, color:'red'}}>Start cost must be less than the end cost.</FormHelperText>}
              </div>
            </Grid>
            <Grid className={classes.descriptionSpacing} container justify="space-between">
              <Typography color='secondary' inline variant="body1" align="left">
                <Box fontWeight="fontWeightBold">Scholarship Available</Box>
              </Typography>
              <FormControl style={{marginBottom:'10px', minWidth:'223px'}} variant="outlined">
                <InputLabel>Scholarship Available</InputLabel>
                <Select
                  id="scholarship"
                  value={scholarshipAvailable}
                  onChange={(event) => setScholarshipAvailable(event.target.value)}
                  label="Scholarship Available"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.descriptionSpacing} container justify="space-between">
              <Typography color='secondary' inline variant="body1" align="left">
                <Box fontWeight="fontWeightBold">Remote</Box>
              </Typography>
              <FormControl style={{marginBottom:'10px', minWidth:'223px'}} variant="outlined">
                <InputLabel>Remote</InputLabel>
                <Select
                  id="remote"
                  value={remote}
                  onChange={(event) => setRemote(event.target.value)}
                  label="Remote"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
        </div>
        <div className={classes.root}>
            <ExpansionPanel style={{width:"100%"}} expanded={expanded === 'panel1'} onChange={handleDetailsChange('panel1')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color='secondary' variant="body1">
                <Box fontWeight="fontWeightBold">More Details</Box>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="column">

                  <Typography color='secondary' inline variant="body1" align="left">
                    <Box fontWeight="fontWeightBold">Program Duration: </Box>
                  </Typography>
                  <div style={{marginBottom:'10px'}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker style={{width:'150px'}}
                        margin="normal"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        value={startDate}
                        onChange={handleStartDateChange}
                        error={durationError || startDateError}
                        helperText={startDateError && "Invalid date format."}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <KeyboardDatePicker style={{marginLeft:'5px', width:'150px'}}
                        margin="normal"
                        label="End Date"
                        format="MM/dd/yyyy"
                        value={endDate}
                        onChange={handleEndDateChange}
                        error={durationError || endDateError}
                        helperText={endDateError && "Invalid date format."}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {!startDateError && !endDateError && durationError && <FormHelperText style={{width:305, color:'red'}}>Start date must be earlier than or the same as the end date.</FormHelperText>}
                  </div>
                  <Typography color='secondary' inline variant="body1" align="left" style={{marginTop:10}}>
                    <Box fontWeight="fontWeightBold">Establishment Date: </Box>
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker style={{marginTop:'5px', width:'305px'}}
                      margin="normal"
                      label="Establishment Date"
                      format="MM/dd/yyyy"
                      value={established}
                      maxDate={new Date()}
                      onChange={handleEstablishmentDateChange}
                      error={establishmentDateInvalidError || establishmentDateRangeError}
                      helperText={establishmentDateInvalidError ? "Invalid date format." : establishmentDateRangeError ? "Date must be earlier than or the same as today's date." : ""}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Typography color='secondary' inline variant="body1" align="left" style={{marginTop:10}}>
                    <Box fontWeight="fontWeightBold">Mission: </Box>
                  </Typography>
                  <TextField style={{marginTop:'5px'}} id='mission' variant='outlined' onChange={event => setMission(event.target.value)} multiline value={mission} />
                  <Typography color='secondary' inline variant="body1" align="left" style={{marginTop:10}}>
                    <Box fontWeight="fontWeightBold">Description: </Box>
                  </Typography>
                  <TextField style={{marginTop:'5px'}} id='description' variant='outlined' onChange={event => setDescription(event.target.value)} multiline value={description} />
                  <Typography color='secondary' inline variant="body1" align="left" style={{marginTop:10}}>
                    <Box fontWeight="fontWeightBold">Website: </Box>
                  </Typography>
                  <TextField style={{marginTop:'5px'}} id='website' variant='outlined' onChange={event => setWebsite(event.target.value)} value={website} />
              </Grid>
            </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
        {props.isEditing === undefined &&
        <div className={classes.rootBottom}>
          <Button variant="contained" color="primary" component="span" size="small" onClick={handleEditing}>
            Create Program
          </Button>
        </div>
        }
      </div>
    )
  }

export default ProgramForm
