import { fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    justifyContent: 'center',
  },
  addAHeaderPhotoIcon: {
    position: 'absolute',
    marginTop: theme.spacing(38),
    marginLeft: theme.spacing(136),
  },
  widthHeight25: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  addAPhotoIcon: {
    position: 'absolute',
    marginTop: theme.spacing(23),
    marginLeft: theme.spacing(18),
  },
  formError: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: 'red'
    }
  },
  formField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  formRow: {
    display: 'flex',
    marginTop: theme.spacing(5)
  },
  flex1: {
    flex: 1
  },
  labelRoot: {
    fontSize: 21,
    marginBottom: theme.spacing(5)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(8),
  },
  width35ch: {
    width: '35ch',
  },
  width40ch: {
    width: '40ch',
  },
  radio: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  dropdownFieldApi: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '3ch',
    width: '40ch',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '2px solid rgba(0, 0, 0, 0.4)',
    background: "rgba(0, 0, 0, 0.001)",
    fontFamily: 'Calibri (Body)',
    "&:focus-within": {
      outline: "none",
      background: "rgba(0, 0, 0, 0.05)"
    },
    color: 'black'
  },
  width100Percent: {
    width:'100%'
  },
  margin10: {
    margin: theme.spacing(10)
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  smallIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  profileMenu: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    }
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${240}px)`,
    marginLeft: 240,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
    backgroundColor: theme.palette.primary['500']
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  menuSelection: {
    '&:hover': {
      backgroundColor: theme.palette.primary['400'],
      color: 'white'
    },
    backgroundColor: theme.palette.primary.light,
    color: 'white'
  },
}));
