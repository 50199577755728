import React, {useState, useEffect} from 'react';
import config from '../utils/config.js'
import Avatar from '@material-ui/core/Avatar';

const EducatorImage = (props) =>  {
  const [image, setImage] = useState(null)

  useEffect(() => {

      fetch(config.resourceServer + "api/educators/image/" + props.id)
      .then(response => response.json())
      .then((data) => {
        setImage(data.educatorImage)
      })
      .catch(err => console.error(err))


  }, [props.id])

  return (
    <Avatar src={config.resourceServer + 'api/images/' + image} />
  );
}

export default EducatorImage
