import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { Container } from 'semantic-ui-react';


import Home from './components/Home';
import CustomLoginComponent from './components/Login'

import PrimarySearchAppBar from './components/global/SearchBar'
import Search from './components/global/Search'
import Register from './components/registerComponents/ChooseUserType'
import Program from './components/Program'
import Following from './components/menuComponents/Following'
import Notifications from './components/menuComponents/Notifications'
import Likes from './components/menuComponents/Likes'
import ChooseRegisterPage from './components/registerComponents/ChooseRegisterPage'
import ChooseProfilePage from './components/profileComponents/ChooseProfilePage'
import EducatorPublicProfile from './components/profileComponents/EducatorPublicProfile'
import ParentPublicProfile from './components/profileComponents/ParentPublicProfile'
import NewProgram from './components/NewProgram'

import { UserContext } from './components/global/UserContext'

import config from './utils/config';

const HasAccessToRouter = () => {
  const history = useHistory(); // example from react-router
  const [userDB, setUserDB] = useState({
    current_account:null,
    default_account:null,
    email:null,
    first_name:null,
    last_name:null,
    is_educator:null,
    is_parent:null,
    is_provider:null,
    educator_verified:null,
    provider_verified:null,
    educator_id:null,
  })

  const providerValue = useMemo(()=>({userDB, setUserDB}), [userDB, setUserDB])

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/login');
  };

  return (
    <Security
      {...config}
      onAuthRequired={customAuthHandler}
    >
      <UserContext.Provider value={providerValue}>
        <PrimarySearchAppBar/>
        <Container text style={{ marginTop: '7em' }}>
            <Route path="/" exact component={Home} />
            <Route path="/callback" component={LoginCallback} />
            <Route path="/login" component={CustomLoginComponent} />
            <Route path='/program' component={Program} />
            <Route path='/educator' component={EducatorPublicProfile} />
            <Route path='/parent' component={ParentPublicProfile} />
            <Route path='/search' component={Search} />
            <SecureRoute path='/likes' component={Likes} />
            <SecureRoute path='/register' component={Register} />
            <SecureRoute path="/home" component={Home} />
            <SecureRoute path='/profile' component={ChooseProfilePage} />
            <SecureRoute path='/registerInfo' component={ChooseRegisterPage} />
            <SecureRoute path="/following" component={Following} />
            <SecureRoute path="/notifications" component={Notifications} />
            <SecureRoute path="/newProgram" component={NewProgram} />
        </Container>
      </UserContext.Provider>
    </Security>
  );
};

const App = () => (
  <div>
    <Router>
      <HasAccessToRouter />
    </Router>
  </div>
);

export default App;
