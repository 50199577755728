// App Bar with a primary search field
// https://material-ui.com/components/app-bar/
// https://material-ui.com/components/drawers/
import { useOktaAuth } from '@okta/okta-react'
import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { Avatar } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MoreIcon from '@material-ui/icons/MoreVert'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { Link } from 'react-router-dom'
import ListItemText from '@material-ui/core/ListItemText';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import config from '../../utils/config';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GroupIcon from '@material-ui/icons/Group'
import {UserContext} from './UserContext'
import {useStyles} from '../../utils/css.js'

// additional logo icon
import mmLogo from '../../icons/mmLogo.png'

const PrimarySearchAppBar = props => {
  // routing
  const { history } = props
  const { authState, authService } = useOktaAuth()
  const [userInfo, setUserInfo] = useState(null)
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { userDB, setUserDB } = useContext(UserContext)
  const [ searchKeywords, setSearchKeywords ] = useState("")

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const searchInput = () => {
    window.location.href = config.hostServer + "search/" + encodeURIComponent(searchKeywords)
  };


  useEffect(() => {
    if (!authState.isAuthenticated) {
      setUserInfo(null)
    }
    else {
      if (authState.isAuthenticated) {
        const { accessToken } = authState
        authService.getUser().then(info => {
          fetch(config.resourceServer + "api/account/" + info.email, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(response => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            // SET GLOBAL USER

            if (data.user) {
              data.user.token=accessToken
              if (window.sessionStorage.getItem('current_account') && window.sessionStorage.getItem('current_account') !== 'null') {
                data.user.current_account = Number(window.sessionStorage.getItem('current_account'))
              }
              else {
                data.user.current_account = data.user.default_account
              }
              if (data.educator) data.user['educator_verified'] = data.educator.verified
              if (data.provider) data.user['provider_verified'] = data.provider.verified
              if (data.educator && data.educator.id) data.user['educator_id'] = data.educator.id
              if (data.user) setUserDB(data.user)

            }

            // if user logged in but doesn't exist in database, register user
            if (data.user === null && window.location.href !== config.hostServer + "register") {
              window.location.href = config.hostServer + "register"
            }

            /**
             * If user logged in, not in database, but already on register page
             * Allows users to have customized profile menu without profiles to select
             */
            else if ((data.user === null && window.location.href === config.hostServer + "register") || (data.user && !(data.user.is_parent || data.user.is_educator || data.user.is_provider))) {
              setUserInfo(-1)
            }

            else {
              setUserInfo(data);
            }

          })
          .catch(err => {
            console.error(err);
          });
        })
        .catch(err => {
          console.error(err);
        });
      }
    }
  }, [authState, authService, setUserDB]) // Update if authState changes

  const logout = () => {
        const issuer = config.issuer
        const redirectUri = config.hostServer
        const idToken = authState.idToken

        // handle closing menu
        setAnchorEl(null)
        handleMobileMenuClose()

        window.sessionStorage.setItem('current_account', null)

        authService.logout('/')
        // Clear remote session
        window.location.href = `${issuer}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`
      }

  // styling
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  };

  const handleMenuClick = (pageURL) => {
    history.push(pageURL)
    setAnchorEl(null)
    handleMobileMenuClose()
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  };

  // forces userDB refresh
  const handleProfileSwitch = (id) => {
    window.sessionStorage.setItem('current_account', id)
    delete userDB.current_account
    setUserDB(Object.assign({}, {current_account:id}, userDB))
    setAnchorEl(null)
    handleMobileMenuClose()
  };

  function ProfileMenu({ profiles }) {
    return (

        <MenuList disablePadding dense>
          {userInfo !== -1 &&
            <>
              <div>
              {profiles.map(({id, image, type, name}) => (
                <MenuItem className={classes.profileMenu} button onClick={() => handleProfileSwitch(id)}>
                    <Avatar className={classes.smallIcon} src={image} />
                    <ListItemText>{type} - {name}</ListItemText>
                </MenuItem>
              ))}
              </div>
              <Divider variant="middle" />
            </>
          }
          <div>
            {!(userDB.is_parent && userDB.is_educator && userDB.is_provider) &&
            <MenuItem onClick={() => handleMenuClick('/register')}>
              <AddIcon/>
              Add New Profile
            </MenuItem>
            }
          </div>
          <Divider variant="middle" />
          {userInfo !== -1 &&
            <>
              <div>
                <MenuItem onClick={() => handleMenuClick('/profile')}>
                  Manage your {userDB.current_account === 0 ? 'Parent' : userDB.current_account === 1 ? 'Educator' : userDB.current_account === 2 ? 'Provider'
                    : userInfo.user.default_account === 0 ? 'Parent' : userInfo.user.default_account === 1 ? 'Educator' : 'Provider'} Profile
                </MenuItem>
              </div>
              <Divider variant="middle" />
            </>
          }
          <div>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </div>
        </MenuList>

    )
  }

  let profiles = []

  if (userInfo && userInfo.user) {
    if (userInfo.user.is_parent) {
      profiles = profiles.concat({id: 0,  image: userInfo.parent ? config.resourceServer + 'api/images/' + userInfo.parent.picture: "", type: 'Parent', name:  userInfo.parent ? userInfo.parent.display_name : ""})
    }
    if (userInfo.user.is_educator) {
      profiles = profiles.concat({id: 1, image: userInfo.educator ? config.resourceServer + 'api/images/' + userInfo.educator.picture : "", type: 'Educator', name: userInfo.educator ? userInfo.educator.display_name : ""})
    }
    if (userInfo.user.is_provider) {
      profiles = profiles.concat({id: 2, image: userInfo.provider ? config.resourceServer + 'api/images/' + userInfo.provider.picture : "", type: 'Provider', name: userInfo.company ? userInfo.company.name : ""})
    }
  }

  const menuId = 'primary-search-account-menu'
  const profileMenuOptions = userInfo
    ?
    <div>
      <ProfileMenu profiles={profiles}/>
    </div>
    : <MenuItem onClick={() => handleMenuClick('/login')}>Login</MenuItem>

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={() => setAnchorEl(null)}
    >
      {profileMenuOptions}
    </Menu>
  )

  const profilePic = userInfo
    ? userDB.current_account === 0 ? <Avatar src={userInfo.parent ? config.resourceServer + 'api/images/' + userInfo.parent.picture : ""} />
      : userDB.current_account === 1 ? <Avatar src={userInfo.educator ? config.resourceServer + 'api/images/' + userInfo.educator.picture : ""} />
      : <Avatar src={userInfo.provider ? config.resourceServer + 'api/images/' + userInfo.provider.picture : ""} />
    : <AccountCircle />

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
        {profilePic}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.grow}>
    <CssBaseline />
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
        <Toolbar>
        {authState.isAuthenticated && userDB.current_account !== 2 &&
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        }

          <Link to="/" style={{ textDecoration: 'none' }}>
          <img src={mmLogo}
          alt="Malleable Minds"
          className={classes.title}/>
          </Link>

          <div className={classes.search} style={{width:300}}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              fullWidth={true}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e)=>setSearchKeywords(e.target.value)}
              onKeyPress= {(e) => {
                  if (e.key === 'Enter') {
                    searchInput()
                  }
              }}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {profilePic}
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
      <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
          <List style={{marginTop:-9}}>
            {['Likes', 'Following', 'Notifications'].map((text, index) => (
              <ListItem button key={text} component={Link} to={index % 3 === 0 ? '/likes' : index % 3 === 1 ? '/following' : '/notifications'} className={classes.menuSelection}>
                <ListItemIcon style={{color:'white'}}>{index % 3 === 0 ? <FavoriteIcon /> : index % 3 === 1 ? <GroupIcon /> : <NotificationsActiveIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
      </Drawer>
    </div>
  )
}

export default withRouter(PrimarySearchAppBar)
