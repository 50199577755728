// https://www.npmjs.com/package/react-material-ui-carousel
import React from 'react';
import Carousel from "react-material-ui-carousel"
import autoBind from "auto-bind"
import '../styles//slider.scss';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import config from '../utils/config.js'

const compSciImg = config.resourceServer + "api/images/base/computerScience.jpeg"
const fingerPaintImg = config.resourceServer + "api/images/base/fingerPainting.jpeg"
const hikingImg = config.resourceServer + "api/images/base/hiking.jpeg"
const roboticsImg = config.resourceServer + "api/images/base/robotics.jpeg"
const sculptingImg = config.resourceServer + "api/images/base/sculpting.jpeg"
const ultimateImg = config.resourceServer + "api/images/base/ultimate.jpeg"

const useStyles = makeStyles((theme) => ({
    item: {
      pointerEvents: "none"
    },
  }))


function Banner(props) {
    const classes = useStyles()
    const contentPosition = props.contentPosition ? props.contentPosition : "left"
    const totalItems = props.length ? props.length : 3;
    const mediaLength = totalItems - 1;

    let items = [];
    const content = (
        <Grid item xs={12 / totalItems} key="content">
            <CardContent style={{backgroundColor:'#833ab4'}} className={`${"Content"} ${classes.item}`}>

                <Typography className="Title">
                    {props.item.Name}
                </Typography>

                <Typography className="Caption">
                    {props.item.Caption}
                </Typography>

            </CardContent>
        </Grid>
    )

    // <Button variant="outlined" className="ViewButton">
    //     View Now
    // </Button>


    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={12 / totalItems} key={item.Name}>
                <CardMedia
                    className={`${"Media"} ${classes.item}`}
                    image={item.Image}
                    title={item.Name}
                >
                    <Typography className="MediaCaption">
                        {item.Name}
                    </Typography>
                </CardMedia>

            </Grid>
        )

        items.push(media);
    }

    if (contentPosition === "left") {
        items.unshift(content);
    } else if (contentPosition === "right") {
        items.push(content);
    } else if (contentPosition === "middle") {
        items.splice(items.length / 2, 0, content);
    }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    )
}

const items = [
    {
        Name: "Arts",
        Caption: "Find art classes in your area. \n Art programs allow your child to express themselves through painting, music, literature, dance, and more.",
        contentPosition: "left",
        Items: [
            {
                Name: "Finger Painting",
                Image: fingerPaintImg
            },
            {
                Name: "Sculpting",
                Image: sculptingImg
            }
        ]
    },
    {
        Name: "STEM",
        Caption: "Hone your child's STEM skills with these great opportunities! \n STEM education creates critical thinkers, increases science literacy, and enables the next generation of innovators.",
        contentPosition: "middle",
        Items: [
            {
                Name: "Robotics",
                Image: roboticsImg
            },
            {
                Name: "Computer Science",
                Image: compSciImg
            }
        ]
    },
    {
        Name: "Sports",
        Caption: "Stay active! Find new ways for your children to safely socially distance and feel engaged with their peers.",
        contentPosition: "right",
        Items: [
            {
                Name: "Hiking",
                Image: hikingImg
            },
            {
                Name: "Ultimate",
                Image: ultimateImg
            }
        ]
    }
]

class BannerExample extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoPlay: true,
            timer: 500,
            animation: "fade",
            indicators: true,
            timeout: 500,
            navButtonsAlwaysVisible: false,
            navButtonsAlwaysInvisible: false
        }

        autoBind(this);
    }

    toggleAutoPlay() {
        this.setState({
            autoPlay: !this.state.autoPlay
        })
    }

    toggleIndicators() {
        this.setState({
            indicators: !this.state.indicators
        })
    }

    toggleNavButtonsAlwaysVisible()
    {
        this.setState({
            navButtonsAlwaysVisible: !this.state.navButtonsAlwaysVisible
        })
    }

    toggleNavButtonsAlwaysInvisible()
    {
        this.setState({
            navButtonsAlwaysInvisible: !this.state.navButtonsAlwaysInvisible
        })
    }

    changeAnimation(event) {
        this.setState({
            animation: event.target.value
        })
    }

    changeTimeout(event, value) {
        this.setState({
            timeout: value
        })
    }

    render() {
        return (
            <div style={{marginTop: "50px", color: "#494949"}}>

                <Carousel
                    className="Example"
                    autoPlay={this.state.autoPlay}
                    timer={this.state.timer}
                    animation={this.state.animation}
                    indicators={this.state.indicators}
                    timeout={this.state.timeout}
                    navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                    navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
                >
                    {
                        items.map((item, index) => {
                            return <Banner item={item} key={index} contentPosition={item.contentPosition}/>
                        })
                    }
                </Carousel>

            </div>

        )
    }
}

export default BannerExample;
