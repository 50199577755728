import { makeStyles } from '@material-ui/core/styles';
import React, {useContext} from 'react';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'
import Rating from '@material-ui/lab/Rating'
import Favorited from '../Favorited'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import config from '../../utils/config.js'
import ProgramImage from '../ProgramImage'
import { UserContext } from '../global/UserContext'
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import { Image } from "react-native";

const useStyles = makeStyles((theme) => ({
  listItem: {
    "&:hover": {
      backgroundColor:theme.palette.grey[100],
      textDecoration:"none",
    },
    marginBottom:'-12px',
    marginTop:'5px'
  },
  favIcon: {
    position: 'absolute',
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor:theme.palette.grey[300]
    },
    backgroundColor:theme.palette.grey[200],
    padding:'5px',
  },
  favIconPosition: {
    width:'4%',
    marginTop:'-12px'
  },
  ratingLabel: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    color: 'dimgray',
  },
  rating: {
    marginLeft: theme.spacing(5),
  },
  ratings: {
    display: 'inline-block'
  },

}));

const Like = (props) => {
  const classes = useStyles()
  const item = props.item
  const { userDB } = useContext(UserContext)

  return (
    <List>
      <ListItem alignItems='flex-start' divider={true} className={classes.listItem} component={Link} to={"/program/"+item.program.id}>
        <Grid container justify="space-between">
            <div style={{width:'70%'}}>
              <Typography variant="h4" color='primary'>{item.program.name}</Typography>
              <Typography color='secondary' variant="h5" component="p">{item.company.name}</Typography>
              <Typography color='primary' variant="h6" component="p">{item.program.city}</Typography>
              <div className={classes.ratings}>
              <Typography variant="h5" component="p" className={classes.ratingLabel}>Parent Rating</Typography>
              <Rating name="rating" defaultValue={item.program.parent_review} precision={0.5} className={classes.rating} readOnly/>
              </div>
              <div className={classes.ratings}>
              <Typography variant="h5" component="p" className={classes.ratingLabel}>Educator Rating</Typography>
              <Rating name="rating" defaultValue={item.program.educator_review} precision={0.5} className={classes.rating} readOnly/>
              </div>
            </div>


            <div style={{width:'25%'}}>
              {item.program.main_picture &&
                <Image
                    style={{
                      resizeMode: "cover",
                      height: 200,
                      width: 300
                    }}
                    source={config.resourceServer + 'api/images/' + item.program.main_picture}
                  />
              }
              {!item.program.main_picture &&
                <ProgramImage id={item.program.id} />
              }
            </div>

          <div onClick={e => e.preventDefault()} className={classes.favIconPosition}>
            {(userDB.current_account === 0 || userDB.current_account === 1) && <Favorited id={item.program.id} name={item.program.name} size="large" style={classes.favIcon}/>}
          </div>
        </Grid>

      </ListItem>
    </List>
  )
}

export default Like
