import React, { useEffect, useContext, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import config from '../utils/config';
import { UserContext } from './global/UserContext'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReplyIcon from '@material-ui/icons/Reply';
import IconButton from '@material-ui/core/IconButton';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { Avatar } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    medium: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      marginRight: "20px"
    },
    reviewBottom: {
      marginBottom: "15px"
    },
    reviewRating: {
      paddingTop: "3px",
      paddingLeft: "10px"
    },
    topElementPadding: {
      paddingTop: "10px",
      paddingLeft: "10px"
    },
    bottomElementPadding: {
      paddingBottom: "10px",
      paddingTop: "3px",
      paddingLeft: "10px"
    }
  }))


const Review = (props) => {
    const classes = useStyles()
    const { authState, authService } = useOktaAuth();
    const { userDB } = useContext(UserContext)
    const isOwner = props.isOwner
    const review = props.review
    const [openReply, setReplyOpen] = useState(false)

    const handleClickReplyOpen = () => {
      setReplyOpen(true);
    };

    const handleCloseReply = () => {
      setReplyOpen(false);
    };

    const addReply = (review, response) => {
        let replyMethod = review.response ? 'PUT' : 'POST'
        let replyBody = replyMethod === 'PUT' ?
          JSON.stringify({'user_email': userDB.email, 'id': review.response_id,
            'review_id':review.review.id, 'description': response}) :
          JSON.stringify({'user_email': userDB.email, 'review_id': review.review.id,
            'description': response})

        if (authState.isAuthenticated) {
          const { accessToken } = authState
          authService.getUser().then(() => {
            fetch(config.resourceServer + '/api/reviews/response', {
              method: replyMethod,
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: replyBody
            })
            .then(response => {
              if (!response.ok) {
              return Promise.reject();
              }
              return response.json();
            })
            .catch(err => {
                console.error(err);
            });
          })
        }
    }

    return(
        <div>
            <Grid className={classes.reviewBottom} container>
            <div style={{flex:1}}>
                <Grid container>
                <Avatar className={classes.medium} src={config.resourceServer + 'api/images/' + review.profile_photo} />
                <Grid direction="column">
                    {!review.review.educator_review &&
                    <Typography component={Link} to={"/parent/" + review.id} variant="h5" color="secondary">
                    {review.display_name}
                    </Typography>
                    }
                    {review.review.educator_review &&
                    <Typography component={Link} to={"/educator/" + review.id} variant="h5" color="secondary">
                    {review.display_name}
                    </Typography>
                    }
                    <Box fontStyle="italic">
                    <Typography variant="h8" color="primary">
                        {review.review.date !== null && new Date(review.review.date).toLocaleDateString("en-US", {timeZone: "Europe/London"})}
                    </Typography>
                    </Box>
                </Grid>
                </Grid>
            </div>
            <div style={{alignSelf:'flex-end'}}>
                {isOwner && authState.isAuthenticated &&
                <div>
                <IconButton color="primary" onClick={() => handleClickReplyOpen()}>
                    <ReplyIcon />
                </IconButton>
                <Dialog open={openReply} onClose={handleCloseReply} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" >
                <Typography variant="h6" color="primary">Reply to {review.display_name}</Typography>
                    </DialogTitle>
                    <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="reply"
                        label="Reply"
                        multiline
                        fullWidth
                        rows={8}
                        variant="outlined"al
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseReply} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() =>  {handleCloseReply();
                                            addReply(review, document.getElementById('reply').value);
                                            review.response = document.getElementById('reply').value
                                            }} color="primary">
                        Post Reply
                    </Button>
                    </DialogActions>
                </Dialog>
                </div>
                }
            </div>
            </Grid>
            <AppBar className={classes.reviewBottom} position="static">
            {!review.review.educator_review &&
            <Grid className={classes.topElementPadding} container>
                <Rating className={classes.topElementPadding} name="Engagement Rating" precision={0.5} value={review.parent_review.engaged} readOnly />
                <Typography className={classes.topElementPadding} inline variant="body1">Positively Engaged Child</Typography>
            </Grid>
            }
            {review.review.educator_review &&
            <Grid className={classes.topElementPadding} container>
                <Rating className={classes.topElementPadding} name="Engagement Rating" precision={0.5} value={review.edu_review.appropriate} readOnly />
                <Typography className={classes.topElementPadding} inline variant="body1">Appropriate for Age</Typography>
            </Grid>
            }
            {!review.review.educator_review &&
            <Grid className={classes.reviewRating} container>
                <Rating className={classes.reviewRating} name="Safe Rating" precision={0.5} value={review.parent_review.safe} readOnly />
                <Typography className={classes.reviewRating} inline variant="body1">Safe</Typography>
            </Grid>
            }
            {review.review.educator_review &&
            <Grid className={classes.reviewRating} container>
                <Rating className={classes.reviewRating} name="Safe Rating" precision={0.5} value={review.edu_review.knowledgeable} readOnly />
                <Typography className={classes.reviewRating} inline variant="body1">Knowledgeable Staff</Typography>
            </Grid>
            }
            <Grid className={classes.reviewRating} container>
                <Rating className={classes.reviewRating} name="Value Rating" precision={0.5}  value={review.review.value} readOnly />
                <Typography className={classes.reviewRating} inline variant="body1">Value</Typography>
            </Grid>
            <Grid className={classes.bottomElementPadding} container>
                <Rating className={classes.bottomElementPadding} name="Recommendation Rating" precision={0.5}  value={review.review.recommend} readOnly />
                <Typography className={classes.bottomElementPadding} inline variant="body1">Recommend to Others</Typography>
            </Grid>
            </AppBar>
            <Typography>{review.review.description}</Typography>
            {review.response &&
            <Grid container>
            <SubdirectoryArrowRightIcon color='primary'/>
            <Grid direction="column">
                <Typography variant="h8" color="secondary">
                {review.response_name && review.response_name + ' -'} Program Owner
                </Typography>
                <Typography>{review.response}</Typography>
            </Grid>
            </Grid>
            }
            <Divider style={{marginTop:'15px', marginBottom:'15px'}} variant="middle"/>
        </div>
    )}

export default Review
