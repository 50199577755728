// https://material-ui.com/components/text-fields/
// add error text fields on save
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useState, useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ContactInfo from '../formComponents/ContactInfo'
import LocationInfo from '../formComponents/LocationInfo'
import ChildInfo from '../formComponents/ChildInfo'
import Rating from '@material-ui/lab/Rating';
import PhoneIcon from '@material-ui/icons/Phone'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ChildCareIcon from '@material-ui/icons/ChildCare';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import RateReviewIcon from '@material-ui/icons/RateReview'
import AddPhoto from '../formComponents/AddPhoto'

import { UserContext } from '../global/UserContext'
import config from '../../utils/config.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    marginBottom: '20px',
    justifyContent: 'center',
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  editBox: {
    position: 'absolute',
    marginTop: theme.spacing(22),
    marginLeft: theme.spacing(18),
  },
  topMargin: {
    marginTop: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(5),
    width: '20ch',
  },
  disableBlueOutline: {
    "&:focus-within": {
      outline: "none"
    },
  },
  reviewSpacing: {
    marginRight: "10px"
  },
  reviewBottom: {
    marginBottom: "15px"
  },
  reviewRating: {
    paddingTop: "3px",
    paddingLeft: "10px"
  },
  topElementPadding: {
    paddingTop: "10px",
    paddingLeft: "10px"
  },
  bottomElementPadding: {
    paddingBottom: "10px",
    paddingTop: "3px",
    paddingLeft: "10px"
  }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

const ParentProfile = () => {
  const { userDB } = useContext(UserContext)
  const { authState, authService } = useOktaAuth()
  const classes = useStyles()
  const theme = useTheme();

  // stepper information
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState(userDB.default_account === 0)

  // parent specific contact fields
  const [ displayName, setDisplayName] = useState(userDB.first_name)
  const [ email ] = useState(userDB.email)
  const [ userTypeEmail, setUserTypeEmail] = useState(userDB.email)
  const [ phone, setPhone ] = useState(null)

  // parent specific location fields
  const [ country, setCountry ] = useState(null)
  const [ region, setRegion ] = useState(null)
  const [ city, setCity ] = useState(null)

  // parent specific child fields
  const [ childRows, setChildRows ] = useState([])
  const [ childError, setChildError ] = useState(false)

  // parent specific reviews
  const [reviewsArray, setReviewsArray] = React.useState([]);

  const handleReviewChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleCheckboxChange = (event) => {
    setState(event.target.checked)
    userDB['default_account']=0

    const { accessToken } = authState
      fetch(config.resourceServer + "api/users/" + userDB.email, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({"default_account":0}),
      })
      .then(response => {return response.json()})
  }

  const saveChanges = () => {
    const { accessToken } = authState
    if (value === 0 || value === 1) {
      fetch(config.resourceServer + 'api/parents/' + userDB.email, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({display_name:displayName, par_email:userTypeEmail, phone:phone, country:country, region:region, city:city}),
      })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
    }
    else {
      let error = false

      for (let i=0; i < childRows.length; i++) {
        if (!childRows[i].name || !childRows[i].grade) {
          error = true
          setChildError(true)
          break
        }
      }

      if (!error) {
        setChildError(false)
        fetch(config.resourceServer + "api/children/uppost/" + userDB.email, {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({child_list: childRows}),
        })
        .then(response => response.json());
      }
    }
  }

  const review = (reviewNum) =>
  <div>
      <Typography variant="h5" color="primary">
        Review for {reviewsArray[reviewNum].program_name}
      </Typography>
      <div className={classes.reviewBottom}>
        <Box fontStyle="italic">
          <Typography variant="h8" color="secondary">
            {reviewsArray[reviewNum].review.date !== null && new Date(reviewsArray[reviewNum].review.date).toLocaleDateString("en-US", {timeZone: "Europe/London"})}
          </Typography>
        </Box>
      </div>
      <AppBar className={classes.reviewBottom} position="static">
        <Grid className={classes.topElementPadding} container>
          <Rating className={classes.topElementPadding} name="Engagement Rating" precision={0.5} value={reviewsArray[reviewNum].parent_review.engaged} readOnly />
          <Typography className={classes.topElementPadding} inline variant="body1">Positively Engaged Child</Typography>
        </Grid>
        <Grid className={classes.reviewRating} container>
          <Rating className={classes.reviewRating} name="Safe Rating" precision={0.5} value={reviewsArray[reviewNum].parent_review.safe} readOnly />
          <Typography className={classes.reviewRating} inline variant="body1">Safe</Typography>
        </Grid>
        <Grid className={classes.reviewRating} container>
          <Rating className={classes.reviewRating} name="Value Rating" precision={0.5} value={reviewsArray[reviewNum].review.value} readOnly />
          <Typography className={classes.reviewRating} inline variant="body1">Value</Typography>
        </Grid>
        <Grid className={classes.bottomElementPadding} container>
          <Rating className={classes.bottomElementPadding} name="Recommendation Rating" precision={0.5} value={reviewsArray[reviewNum].review.recommend} readOnly />
          <Typography className={classes.bottomElementPadding} inline variant="body1">Recommend to Others</Typography>
        </Grid>
      </AppBar>
      <Typography className={classes.reviewBottom}>{reviewsArray[reviewNum].review.description}</Typography>
      {reviewNum !== (reviewsArray.length-1) &&
      <Divider className={classes.root} variant="middle"/>}
  </div>

  useEffect(() => {
    document.title = 'Malleable Minds • Profile'

    // get user reviews
    if (authState.isAuthenticated) {
      authService.getUser().then(info => {
        fetch(config.resourceServer + '/api/users/' + info.email + '/reviews')
        .then(response => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          setReviewsArray(data.parent_reviews)
        })
        .catch((err) => {
          console.error(err);
        });
      })
    }

    // get parent information
    const { accessToken } = authState
    fetch(config.resourceServer + 'api/parents/' + userDB.email, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(response => { return response.json()})
    .then((data) => {
      if (data.parent) {
        if (data.parent.display_name)setDisplayName(data.parent.display_name)
        if (data.parent.par_email)setUserTypeEmail(data.parent.par_email)
        setPhone(data.parent.phone)
        setCountry(data.parent.country)
        setRegion(data.parent.region)
        setCity(data.parent.city)
        setChildRows(data.children)
      }
    })
    .catch((err) => console.error(err))
  }, [authState, authService, userDB.email])

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            disabled={state}
            checked={state}
            onChange={e=>handleCheckboxChange(e)}
            name="checkedB"
            color="primary"
            style={{marginTop:'-0.25em'}}
          />
        }
        label="Make Default Profile"
      />

      <AddPhoto />

      <div>
      <AppBar position="static" color="default" className={classes.topMargin}>
        <Tabs
            value={value}
            onChange={handleReviewChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
          <Tab label="Contact Information" icon={<PhoneIcon />} className={classes.disableBlueOutline}/>
          <Tab label="Location Information" icon={<LocationOnIcon />} className={classes.disableBlueOutline} />
          <Tab label="Child Information" icon={<ChildCareIcon />} className={classes.disableBlueOutline} />
          <Tab label="Reviews" icon={<RateReviewIcon />} className={classes.disableBlueOutline} />
        </Tabs>
      </AppBar>
      <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
      >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <ContactInfo userType="Parent" setDisplayName={setDisplayName} displayName={displayName} email={email} setUserTypeEmail={setUserTypeEmail} userTypeEmail={userTypeEmail} setPhone={setPhone} phone={phone}/>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <LocationInfo setCountry={setCountry} country={country} setRegion={setRegion} region={region} setCity={setCity} city={city}/>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ChildInfo setChildRows={setChildRows} childRows={childRows} setChildError={setChildError} childError={childError}/>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            { function() {
              let rows = []
              for (let i=0; i < reviewsArray.length; i++) {
                rows.push(review(i))
              }
              return rows
            }() }
            {!reviewsArray.length &&
            <Typography>No Reviews Yet!</Typography>}
          </TabPanel>
      </SwipeableViews>
      </div>
      { value !== 3 &&
      <div className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={saveChanges}
        >
          Save
        </Button>
      </div>
    }
    </div>
  )
}

// <Button
//   variant="contained"
//   color="primary"
//   component="span"
//   width="20em"
//   className={classes.button}
//   startIcon={<DeleteIcon />}
// >
//   Cancel
// </Button>

export default ParentProfile
