import React, {useContext, useEffect} from 'react';
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {Typography} from '@material-ui/core'
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1'
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1'
import IconButton from '@material-ui/core/IconButton'
import FormHelperText from '@material-ui/core/FormHelperText';
import { UserContext } from '../global/UserContext'
import Radio from '@material-ui/core/Radio'
import FormLabel from '@material-ui/core/FormLabel';
import {useStyles} from '../../utils/css.js'

const ExperienceInfo = (props) => {
  const classes = useStyles()
  const { userDB } = useContext(UserContext)
  const [numExperiences, setNumExperiences] = React.useState(props.experienceRows.length)
  const [selectedRole, setSelectedRole] = React.useState(null);

  useEffect(() => {
    props.experienceRows.map((role,index) => {
      if (role.main) setSelectedRole(index)
    })
  }, [props] )

  const addExperience = () => {
    setNumExperiences(numExperiences+1)

    props.setExperienceRows(props.experienceRows.concat({role:"",years:"", main:false, educator_user_email:userDB.email}))
  }

  const deleteExperience = () => {
    setNumExperiences(numExperiences-1)

    if (selectedRole === numExperiences-1) {
      props.experienceRows[0].main = true
      setSelectedRole(0)
    }
    props.experienceRows.pop()
  }

  const changeExperienceTitle = (value, experienceNum) => {
    props.experienceRows[experienceNum].role = value
  }

  const changeExperienceYears = (value, experienceNum) => {
    props.experienceRows[experienceNum].years = value
  }

  const changeMainRole = (experienceNum) => {
    props.experienceRows[experienceNum].main = true
    props.experienceRows[selectedRole].main = false
    setSelectedRole(experienceNum)
  }

  const formItem = (experienceNum) =>
    <div>
      <div className={classes.formRow}>
        <Radio
          className={classes.radio}
          checked={selectedRole === experienceNum}
          onChange={()=>changeMainRole(experienceNum)}
          value={experienceNum}
        />
        <div className={classes.flex1}>
            <InputLabel className={classes.formField}>
              Title
            </InputLabel>
            <TextField
              className={classes.formField}
              defaultValue={props.experienceRows[experienceNum].role}
              onChange={(e)=>changeExperienceTitle(e.target.value, experienceNum)}
              helperText={props.experienceError && !props.experienceRows[experienceNum].role ? "Field cannot be empty" : ""}
              error={props.experienceError && !props.experienceRows[experienceNum].role}
            />
        </div>
        <div style={{ alignSelf: 'flex-end', marginLeft: 20 }}>
        <InputLabel className={classes.formField}>
          Years
        </InputLabel>
        <Select className={classes.formField}
          defaultValue={props.experienceRows[experienceNum].years}
          onChange={(e)=>changeExperienceYears(e.target.value, experienceNum)}
          error={props.experienceError && numExperiences && !props.experienceRows[experienceNum].years}
          >
          <MenuItem value={1}>Less than 1</MenuItem>
          <MenuItem value={2}>1-3</MenuItem>
          <MenuItem value={3}>4-6</MenuItem>
          <MenuItem value={4}>7-10</MenuItem>
          <MenuItem value={5}>10+</MenuItem>
        </Select>
        <FormHelperText className={classes.formField} style={props.experienceError && !props.experienceRows[experienceNum].years ? {color:'red'}: {}}>
            {props.experienceError && !props.experienceRows[experienceNum].years ? "Field cannot be empty" : ""}
        </FormHelperText>
        </div>
      </div>
    </div>

  return (
    <div>
      <Typography align="center" variant="h4" component="h4" color='primary'>
        Experiences
      </Typography>

      <div className={classes.flexCenter + ' ' + classes.formError}>
        <form noValidate autoComplete="off">
          { function() {
            let rows = []
            for (let i=0; i < numExperiences; i++) {
              rows.push(formItem(i))
            }
            return rows
          }() }
          <div>
            <IconButton
              onClick={() => addExperience()}
              color="primary"
            >
              <ExposurePlus1Icon />
            </IconButton>
            { numExperiences > 1 &&
              <IconButton
                onClick={() => deleteExperience()}
                color="primary"
              >
                <ExposureNeg1Icon />
              </IconButton>
            }

          </div>
        </form>
      </div>
    </div>

  )
}

export default ExperienceInfo
