import React, {useState, useContext, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AvatarEditor from 'react-avatar-editor'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { UserContext } from '../global/UserContext'
import { useOktaAuth } from '@okta/okta-react'
import config from '../../utils/config.js'
import {useStyles} from '../../utils/css.js'

const AddPhoto = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState("")
  const [prevImg, setPrevImg] = useState("")
  const [editor, setEditor] = useState(null)
  const { userDB } = useContext(UserContext)
  const { authState } = useOktaAuth()
  const userType = userDB.current_account === 0 ? "parents/" : userDB.current_account === 1 ? "educators/" : "providers/"

  useEffect(() => {
    if (!image) {
      const { accessToken } = authState
      fetch(config.resourceServer + 'api/' + userType + userDB.email, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => { return response.json()})
      .then((data) => {
        let url = ""
        if (userType === "parents/" && data.parent.picture) url = data.parent.picture
        else if (userType === "educators/" && data.educator.picture) url = data.educator.picture
        else url = data.provider.picture
        if (url !== ""){
          setImage(config.resourceServer + 'api/images/' + url)
        }
      })
      .catch((err) => console.error(err))
    }
  }, [userDB.email, authState, image, userType])

  const handleNewImage = e => {
    const fr = new FileReader()
    fr.addEventListener("load", function(){
      if (!prevImg){
        setPrevImg(image)
      }
      setImage(fr.result)
    }, false)
    fr.readAsDataURL(e.target.files[0])
  }

  const saveImage = () => {
    const { accessToken } = authState
    const canvas = editor.getImage().toDataURL()
    fetch(config.resourceServer + 'api/' + userType + userDB.email, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({picture:canvas}),
    })
    .then(response => {
      setImage("")
      setPrevImg("")
      return response.json()})
    .catch((error) => console.error('Error:', error))
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setImage(prevImg)
    setPrevImg("")
    setOpen(false)
  };

  const setEditorRef = editor => {
    if (editor) setEditor(editor)
  }

  return (
      <div className={classes.flexCenter}>
        <Avatar alt="Profile Picture" src={image} className={classes.widthHeight25} />
        <div className={classes.addAPhotoIcon}>
          <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen}>
            <AddAPhotoIcon />
          </IconButton>
        </div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" >
            <Typography variant="h6" color="primary">Upload a Profile Picture</Typography>
          </DialogTitle>
          <DialogContent>
            <AvatarEditor
              ref={setEditorRef}
              image={image}
              width={250}
              height={250}
              border={15}
              color={[200, 200, 200, 0.6]} // RGBA
              scale={1.2}
              rotate={0}
              borderRadius={150}
              crossOrigin="anonymous"
            />
            <div>
            <input name="newImage" type="file" onChange={handleNewImage} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={saveImage} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  )
}

export default AddPhoto
