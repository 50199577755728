import React, {useContext} from 'react'
import { UserContext } from '../global/UserContext'
import EducatorProfile from './EducatorProfile'
import ParentProfile from './ParentProfile'
import ProviderProfile from './ProviderProfile'

const ChooseProfilePage = () => {
  const { userDB } = useContext(UserContext)

  const userStepper = (userIndex) => {
    switch (userIndex) {
      case 0:
        return <ParentProfile />;
      case 1:
        return <EducatorProfile />;
      case 2:
        return <ProviderProfile />;
      default:
        return '';
    }
  }

  return (
    <div>
    {userStepper(userDB.current_account)}
    </div>
  )
}

export default ChooseProfilePage
