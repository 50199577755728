import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useOktaAuth } from '@okta/okta-react'
import { UserContext } from '../global/UserContext'
import config from '../../utils/config.js'
import EducatorImage from '../EducatorImage.js'
import { Link } from 'react-router-dom'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { ListItemText } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
    textUnchanged: {
        textTransform: "none",
        width: "90px"
    }
  }))

const Followee = (props) => {
    const classes = useStyles()
    const [buttonText, setButtonText] = useState('Follow');
    const [buttonColor, setButtonColor] = useState('primary');
    const { userDB } = useContext(UserContext)
    const { authState, authService } = useOktaAuth()

    const handleFollowChange = (text) => {
        if (text === 1){
            setButtonText('Following')
            setButtonColor('default')
        }

        else {
        setButtonText('Follow')
        setButtonColor('primary')
        }

        if (authState.isAuthenticated) {
            const { accessToken } = authState
            const userType = userDB.current_account === 0 ? 'parents' : 'educators'
            authService.getUser().then(info => {
            fetch(config.resourceServer + '/api/follows/' + userType + '/' + info.email + '/' + props.followee.id, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                }
            })
            .then(response => {
                if (!response.ok) {
                return Promise.reject();
                }
                return response.json();
            })
            .catch(err => {
                console.error(err);
            });
            })
        }
    }

    useEffect(() => {
        if (authState.isAuthenticated && userDB.email) {
            const { accessToken } = authState
            const isEducator = userDB.current_account === 1 ? 1 : 0
            fetch(config.resourceServer + "/api/isfollowing/" + userDB.email + "/" + isEducator + "/" + props.followee.id, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            }
            })
            .then(response => {
            if (!response.ok) {
                return Promise.reject();
            }
            return response.json();
            })
            .then(data => {
                setButtonText(data.isFollowing ? "Following" : "Follow")
                setButtonColor(data.isFollowing ? "default" : "primary")
            })
        }
    }, [buttonText, buttonColor, userDB.email])

    return (
        <List>
            <ListItem style={{marginBottom:'-16px'}} button component={Link} to={"/educator/" + props.followee.id} className={classes.root} divider={true}>
                <ListItemAvatar>
                    {props.followee.picture &&
                        <Avatar src={config.resourceServer + 'api/images/' + props.followee.picture} />
                    }
                    {!props.followee.picture &&
                        <EducatorImage id={props.followee.id} />
                    }
                </ListItemAvatar>
                <ListItemText
                    primary={<Typography className={classes.root} color='secondary' variant="h5">{props.followee.display_name}</Typography>}
                    secondary={<Typography className={classes.root} color='primary' variant="body1">{props.followee.main_role}</Typography>}
                />
                <ListItemSecondaryAction>
                {authState.isAuthenticated && userDB.current_account !== 2 && userDB.educator_id !== props.followee.id &&
                        <Button color={buttonColor} className={classes.textUnchanged} disableElevation variant="contained" onClick={() =>
                            buttonText === "Following" ? handleFollowChange(0) : handleFollowChange(1)}>{buttonText}</Button>
                }
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    )
  }

export default Followee
