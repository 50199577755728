import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Slider from './Slider';
import Footer from './global/Footer';
import {UserContext} from './global/UserContext'
import config from '../utils/config';
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile';
import {Typography} from '@material-ui/core'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import Favorited from './Favorited'
import InputLabel from '@material-ui/core/InputLabel';
import FilterBy from './FilterBy'
import defaultImg from '../images/base/default_img_icon.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  header: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.light,
    align: 'left',
  },
}))

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > * + *': {
//       marginTop: theme.spacing(0.25),
//     },
//     "&:hover": {
//       textDecoration: 'none',
//     },
//     position: 'relative',
//     width:'100vw',
//     left: '50%',
//     right: '50%',
//     marginLeft: '-50vw',
//     marginRight: '-50vw',
//     marginTop: '-2em'
//
//   },
// }));

const Home = () => {
  const classes = useStyles()
  const { authState } = useOktaAuth()
  const { userDB } = useContext(UserContext)
  const [ programs, setPrograms ] = useState(null)
  const [ showLikes, setShowLikes ] = useState(false)
  const [options, setOptions] = useState([])

  // search bar forces redirect on new user
  useEffect(() => {
    document.title = 'Malleable Minds • Home'

    if (!programs) {
      fetch(config.resourceServer + "api/programs")
        .then(response => response.json())
        .then((data) => setPrograms(data))
        .catch(err => console.error(err))
    }

    // if (authState.isAuthenticated && userDB.current_account === 0 && userDB.email) {
    //   const { accessToken } = authState
    //
    //   fetch(config.resourceServer + 'api/parents/' + userDB.email + "/children", {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   })
    //   .then(response => { return response.json()})
    //   .then((data) => {
    //     setOptions(options.concat(data.children.map(child => child.grade-1)))
    //   })
    //   .catch((err) => console.error(err))
    // }

    if (authState.isAuthenticated && (userDB.current_account === 0 || userDB.current_account === 1)) setShowLikes(true)
    else setShowLikes(false)
  }, [userDB, authState])

  // pull user from backend. If user & missing fields, send to registration
  // const completeRegistrationAlert = userAuth && (userDB.country == null) &&
  //   <div>
  //     <Alert
  //       severity="info"
  //       className={classes.root}
  //       component={Link}
  //       to="/registerInfo">
  //       Click here to complete registration steps
  //     </Alert>
  //     </div>
  //
  //     <div>
  //       {completeRegistrationAlert}
  //     </div>

  const programRow = (props, cols) =>  {
    const widthCols = cols < 3 ? 600 : 500
    return (
      <div>
      <div className={classes.root}>
        <Typography variant="h4" component="h4" className={classes.header}>
          {"This "+props[0].type}
        </Typography>
        </div>
        <div className={classes.root}>
          <GridList className={classes.gridList} cols={cols} cellHeight={400}>
            {props.map((tile, index) => (
              <GridListTile>
              <Link to={"/program/"+tile.id}><img style={{maxWidth:widthCols}} src={tile.main_picture ? config.resourceServer + 'api/images/' + tile.main_picture : defaultImg} alt={tile.Name} height={400} /></Link>
              <GridListTileBar
                title={tile.name}
                subtitle={tile.description ? tile.description : " "}
                actionIcon={showLikes && <Favorited id={tile.id} name={tile.name}/> }
              />
              </GridListTile>
            ))}
          </GridList>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Slider />

      {programs &&
        programs.program_types.map((key) => (
        programRow(programs.programs[key], programs.programs[key].length > 3 ? 3.5 : programs.programs[key].length)
      ))}

      <Footer />
    </div>
  )
}

// {userDB.current_account === 0 && options.length !== 0 &&
//   <div>
//   <InputLabel className={classes.title}>
//     Filter by grade
//   </InputLabel>
//   <FilterBy options={options}/>
//   </div>
// }

export default Home
