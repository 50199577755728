import React, {useEffect, useState} from 'react';
import SuggestedEdit from './SuggestedEdit'
import { useOktaAuth } from '@okta/okta-react'
import Typography from '@material-ui/core/Typography';
import config from '../utils/config.js'
import Divider from '@material-ui/core/Divider';

const SuggestedEdits = (props) => {
    const { authState, authService } = useOktaAuth()
    const [ suggestedEdits, setSuggestedEdits ] = useState(null)
    const [ deleteSuggestion, setDeleteSuggestion ] = useState(false)
    const id = props.id

    useEffect(() => {
        if (authState.isAuthenticated) {
            const { accessToken } = authState
            fetch(config.resourceServer + '/api/programs/' + id + '/edits', {
            headers: { Authorization: `Bearer ${accessToken}` }
            })
            .then(response => response.json())
            .then((data) =>{
                setSuggestedEdits(data.program_edits)
            })
            .catch(err => console.error(err));
        }
    }, [authState, authService, deleteSuggestion, id])

    return (
        <div>
        { suggestedEdits && suggestedEdits.length === 0 &&
        <Typography align='center' color='secondary' inline variant="body1" fontWeight="bold">
            No suggested edits!
        </Typography>
        }
        { suggestedEdits && suggestedEdits.length > 0 &&
        suggestedEdits.map((item) => (
            <div>
                <SuggestedEdit deleteSuggestion={deleteSuggestion} setDeleteSuggestion={setDeleteSuggestion} edit={item}/>
                <Divider variant="middle" style={{marginTop:'10px'}}/>
            </div>
        ))
        }
        </div>
    )
}

export default SuggestedEdits
