// https://material-ui.com/components/text-fields/
// add error text fields on save
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect, useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react'
import { UserContext } from '../global/UserContext'
import config from '../../utils/config.js'
import Rating from '@material-ui/lab/Rating';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    justifyContent: 'center',
  },
  medium: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  },
  reviewBottom: {
    marginBottom: "15px"
  },
  reviewRating: {
    paddingTop: "3px",
    paddingLeft: "10px"
  },
  topElementPadding: {
    paddingTop: "10px",
    paddingLeft: "10px"
  },
  bottomElementPadding: {
    paddingBottom: "10px",
    paddingTop: "3px",
    paddingLeft: "10px"
  }
}));

const ParentPublicProfile = () => {
    const classes = useStyles()
    const [reviewsArray, setReviewsArray] = useState([]);
    const [parentInfo , setParentInfo] = useState([]);
    const { userDB } = useContext(UserContext)
    const { authState, authService } = useOktaAuth()

    const review = (reviewNum) =>
    <div>
        <Typography variant="h5" color="primary">
          Review for {<Typography variant="h5" color="primary" component={Link} to={"/program/" + reviewsArray[reviewNum].program_id}>{reviewsArray[reviewNum].program_name}</Typography>}
        </Typography>
        <div className={classes.reviewBottom}>
          <Box fontStyle="italic">
            <Typography variant="h8" color="secondary">
              {reviewsArray[reviewNum].review.date !== null && new Date(reviewsArray[reviewNum].review.date).toLocaleDateString("en-US", {timeZone: "Europe/London"})}
            </Typography>
          </Box>
        </div>
        <AppBar className={classes.reviewBottom} position="static">
          <Grid className={classes.topElementPadding} container>
            <Rating className={classes.topElementPadding} name="Engagement Rating" precision={0.5} value={reviewsArray[reviewNum].parent_review.engaged} readOnly />
            <Typography className={classes.topElementPadding} inline variant="body1">Positively Engaged Child</Typography>
          </Grid>
          <Grid className={classes.reviewRating} container>
            <Rating className={classes.reviewRating} name="Safe Rating" precision={0.5} value={reviewsArray[reviewNum].parent_review.safe} readOnly />
            <Typography className={classes.reviewRating} inline variant="body1">Safe</Typography>
          </Grid>
          <Grid className={classes.reviewRating} container>
            <Rating className={classes.reviewRating} name="Value Rating" precision={0.5}  value={reviewsArray[reviewNum].review.value} readOnly />
            <Typography className={classes.reviewRating} inline variant="body1">Value</Typography>
          </Grid>
          <Grid className={classes.bottomElementPadding} container>
            <Rating className={classes.bottomElementPadding} name="Recommendation Rating" precision={0.5}  value={reviewsArray[reviewNum].review.recommend} readOnly />
            <Typography className={classes.bottomElementPadding} inline variant="body1">Recommend to Others</Typography>
          </Grid>
        </AppBar>
        <Typography className={classes.reviewBottom}>{reviewsArray[reviewNum].review.description}</Typography>
        {reviewNum !== (reviewsArray.length-1) &&
        <Divider className={classes.root} style={{marginBottom:'10px'}} variant="middle"/>}
    </div>

    const location = () => {
      return([parentInfo.city, parentInfo.region, parentInfo.country].filter(val => val !== '' && val !== undefined).join(', '))
    } 

    useEffect(() => {
      document.title = 'Malleable Minds • Parent'
      const parentID = window.location.pathname.split("/").pop()
      fetch(config.resourceServer + '/api/unauth/parents/' + parentID)
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setReviewsArray(data.reviews)
        setParentInfo(data.parent)
      })
      .catch((err) => {
        console.error(err);
      });
    }, [authState, authService, userDB.current_account])

    return (
      <div>
        {parentInfo !== null &&
        <div>
            <div>
                <Grid style={{marginBottom: '10px'}} className={classes.root}>
                    <Grid>
                        <Avatar className={classes.medium} src={config.resourceServer + 'api/images/' + parentInfo.picture} />
                    </Grid>
                    <Grid container direction='column'>
                        <Grid>
                            <Typography color='primary' variant='h4'>{parentInfo.display_name}</Typography>
                        </Grid>
                        <Grid>
                            <Typography color='secondary' variant='h6'>{location()}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.root} variant="middle"/>
            <div style={{marginTop:'15px'}}>
                <AppBar style={{marginBottom: '10px'}} position="static">
                    <Typography style={{padding: '7px'}} variant="h6">
                    Reviews
                    </Typography>
                </AppBar>
                { function() {
                let rows = []
                for (let i=0; i < reviewsArray.length; i++) {
                    rows.push(review(i))
                }
                return rows
                }() }
                {!reviewsArray.length &&
                <Typography>No Reviews Yet!</Typography>}
            </div>
        </div>
        }
      </div>
    )
}

export default ParentPublicProfile
